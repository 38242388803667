import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(
        private http: HttpClient
    ) { }


    createEmployee(data: EmployeeModel) {

        const url = "/api/Employee/CreateEmployee";
        return this.http.post<any>(url, data);
    }

    updateEmployee(data: EmployeeModel) {

        const url = "/api/Employee/UpdateEmployee";
        return this.http.put<any>(url, data);
    }

    getEmployeeList() {

        const url = `/api/Employee/GetAll/${10000}/${1}`;
        return this.http.get<Array<EmployeeModel>>(url);
    }

    getEmployeeById(empid: number) {

        const url = `/api/Employee/GetEmployeeById/${empid}`;
        return this.http.get<EmployeeModel>(url);
    }

    deleteEmployeeByID(empid: number) {
        const url = `/api/Employee/DeleteEmployee/${empid}`;
        return this.http.delete<any>(url);
    }


    employeeDropdown(): Observable<Array<EmployeeDropDownModel>> {
        const url = "/api/Employee/GetAllSelection";
        return this.http.get<Array<EmployeeDropDownModel>>(url).pipe(
            map(data => {
                return data.map(item => {
                    const data: EmployeeDropDownModel = {
                        empId: item.empId,
                        name: item.name
                    }
                    return data;
                })
            })
        );
    }

}

export interface EmployeeModel {
    empId: number,
    empNo: string,
    name: string,
    departmentId: number,
    departmentName: string,
    hphone: string,
    mphone: string,
    email: string,
    address: string,
    city: string,
    stateCd: string,
    countryCd: string,
    pinCode: string,
    userId: number,
    reportEmpId: number,
    startDate: Date,
    endDate: Date,
    employeeTypId: number,
    blockEmp: boolean
}

export interface EmployeeDropDownModel {
    empId: number,
    name: string
}
