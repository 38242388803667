import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  Form,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductionService,ProductionOrderDetails } from 'src/app/services/production/production.service';
import {
  EmployeeDropDownModel,
  EmployeeService,
} from 'src/app/services/employee/employee.service';
import {
  MachineDropdown,
  MachineService,
} from 'src/app/services/machine/machine.service';
import {
  ProductService,
  ProductStatusDropdown,
} from 'src/app/services/product/product.service';
import {
  CoreMasterService,
  RetailerList,
  WarehouseList,
} from 'src/app/services/core-master/core-master.service';
import { User } from 'src/app/models/user/user';
import { ProductStatusModel } from 'src/app/services/productstatus/productstatus.service';
import {
  WarehouseLocationSelection,
  WarehouseService,
  WHStockSheetModel,
  WHLocationslotModel,
  WHLocationModel,
} from 'src/app/services/warehouse/warehouse.service';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';

import { MatDialog } from '@angular/material/dialog';
import { MessageDailogComponent } from 'src/app/component/shared/message-dailog/message-dailog.component';
import {
  PageService,
  SortService,
  FilterService,
  ContextMenuService,
  ToolbarService,
  GridComponent,
  PageSettingsModel,
  RecordDoubleClickEventArgs,
} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'pp-repair-edit',
  templateUrl: './repair-edit.component.html',
  styleUrls: ['./repair-edit.component.scss'],
  providers: [
    PageService,
    SortService,
    FilterService,
    ContextMenuService,
    ToolbarService,
    DatePipe,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RepairEditComponent implements OnInit {

  filteredList: Array<any>;
  isChecked: boolean = false; 
  ispresent: Array<any>=[];
  locationslotidselect: Array<string>;
stockstatuscd:number=0;
  stocksheetList: Array<WHStockSheetModel>;
  @ViewChild('prEntryForm') productEntryFormElement: NgForm;
  @ViewChild('inspectionFormTemplate') inspectionFormElement: NgForm;
  @ViewChild('basic', { static: true }) basicTab: ElementRef;
  @ViewChild('details', { static: true }) detailsTab: ElementRef;
  @ViewChild('remarks', { static: true }) remarks: ElementRef;
  @ViewChild('rfu') rfu: ElementRef;
  @ViewChild('tbw') tbw: ElementRef;
  @ViewChild('tbr') tbr: ElementRef;
  @ViewChild('tbhw') tbhw: ElementRef;
  @ViewChild('scrap') scrap: ElementRef;
  @ViewChild('timefrom') timefrom: ElementRef;

  @ViewChild('timeto') timeto: ElementRef;
  public dialogRef: MatDialogRef<RepairEditComponent>;
  locationSelected: number;
  public toolbar: string[];
  updatedwhslotdata: Array<WHLocationslotModel> = [];
  tempStocksheetList: Array<WHStockSheetModel>=[];
  whid = 2562;
  warehouselocationDD: Array<WarehouseLocationSelection> = [];
  repairCreatePage = true;
  washForm: FormGroup;
  productEntryForm: FormGroup;
  productionOrderDetailsFormGroup: FormGroup;
  productionOrderdetails:Array<ProductionOrderDetails>=[];
  currentUser: User;
  disablerfu:boolean=true;
  sortingDetailsFormAdd: boolean = true;
  sortingDetailsEditRowNumber: number;
  locationslots:Array<WHLocationslotModel>=[];
  showAddButton: boolean = false;
  processing: boolean = false;
  loading: boolean = false;
  updating: boolean;
  locations: string;

  warehouse: Array<WarehouseList> = [];
  inspectationStatusList: Array<ProductStatusDropdown> = [];

  productCodeSelected: string;
  // sortingDetailsList: Array<SortingDetails> = [];
  employeeDD: Array<EmployeeDropDownModel>;
  machineDD: Array<MachineDropdown>;
  selectedMachine: MachineDropdown;
  productionData: any;
  stockByProductData: any=[];

  stockByProductTBRData: any=[];
  stockByProductTBHWData: any=[];

  stockByProductscrpData: any=[];
  productionExecutionData: any;
  productionExecutionId: any;
  storageLocations: Array<any> = [];
  instockqty:number;
  statusList: Array<ProductStatusModel> = [];

  whLocationData: Array<WHLocationModel> = [];
  newStorageLocationValue: number;
  SetWareHouseName: FormGroup;

  @ViewChild('grid') grid: GridComponent;
  @ViewChild('gridPreview') gridPreview: GridComponent;
  locationslotid:any='';
  public pageSettings: PageSettingsModel;
  public dateFormat: object;
  public customAttributes: Object;
  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private router: Router,
    private machineService: MachineService,
    private employeeService: EmployeeService,
    private coreMasterService: CoreMasterService,
    private warehouseService: WarehouseService,
    private translatePipe: TranslatePipe,
    private dialog: MatDialog,

    private productionService: ProductionService,
    @Inject(MAT_DIALOG_DATA) public documentid: any
  ) { }

  ngOnInit(): void {
    this.stocksheetList=[];
  console.log('this.documentid',this.documentid);

  this.locationslotidselect=[];
    this.dateFormat = { type: 'date', format: 'dd/MM/yyyy' };
    this.pageSettings = { pageSize: 50 };
    this.customAttributes = { class: 'pp-grid-style' };
    this.toolbar = ['Search'];
  
    this.productionOrderdetails=[];
  
    
    this.washForm = this.fb.group({
      sortingId: [''],
      washDate: [new Date(), Validators.required],
      warehouseName: [{ value: '', disabled: true }],
      locationName: [{ value: '', disabled: false }],
      machine: ['', Validators.required],
      employee: ['', Validators.required],
      storageLocation: [{ value: '', disabled: false }],
    });

    console.log(this.washForm);
    this.locationSelected =35;
  
    this.employeeService.employeeDropdown().subscribe((data) => {
      this.employeeDD = data;
    });

    this.machineService.getMachineDropdown().subscribe((data) => {
      this.machineDD = data;
    });

    this.coreMasterService.getWarehouseList().subscribe((data) => {
      this.warehouse = data;
    });
    this.onWarehouseChange({ value: 2562, source: null });

    this.washForm.get("locationName").setValue(this.locationSelected);
    
    console.log('this.productionExecutionId',this.productionExecutionId);

   
      this.GetProductionExecutionById();
  }
  OnSlotNoChange(event:MatSelectChange) {

    console.log(this.isChecked)
    
    console.log(event.value)
        if(event.value){
        this.loading=true;
        this.locationslotidselect.push(event.source.id);
        //this.locationslotid = event.source.id;
       this.AddNewSlotForRepairProcess(event.value);
        setTimeout( () => this.loading = false, 2000 );
        }
        else
        {
          console.log('unchecked')
    
        }
      }
  
      onStorageLocationChange(event: MatSelectChange) {
        if (event.value) {
          this.loading=true;
          this.locationSelected = event.value;
          console.log(this.newStorageLocationValue, event);
          this.SlotsbyWhidandLocation(this.washForm.get('warehouseName').value,this.locationSelected);
          setTimeout(() => {
                      this.loading=false;
                        }, 2000);
        // this.getWareHouseStockSheet();
        } else {
        }
      }
  GetProductionExecutionById() {
debugger;
    this.productionService
      .GetProductionExecutionByID(this.documentid)
      .subscribe(
        (response: any) => {
         
          
          this.productionExecutionData = response;

          console.log('this.productionExecutionData',this.productionExecutionData)
          this.washForm
            .get('warehouseName')
            .setValue(this.productionExecutionData?.whid);
          // this.washForm.get("location").setValue(this.productionExecutionData?.id);
          this.washForm
            .get('machine')
            .setValue(this.productionExecutionData?.machineCd);
          this.washForm
            .get('employee')
            .setValue(this.productionExecutionData?.empId);
            this.updatedwhslotdata=[];
            this.productionOrderdetails=[];

            this.productionData = response;
            console.log('this.productionData',this.productionData) 
           
           if(this.updatedwhslotdata.length===0)
   {
    if(this.productionExecutionData?.productionExecutionNo.startsWith('WASH'))
    {
      this.disablerfu=false;
      this.stockstatuscd=21;
      this.warehouseService
      .GetWareHouseLocSlotsWHId(this.productionExecutionData?.whid)

     .subscribe((Data) => {
      this.updatedwhslotdata=Data.filter(x=>x.locationId==35);
          console.log('this.updatedwhslotdatawash',this.updatedwhslotdata) 
        })
    }
    else
    {
      this.disablerfu=true;
      this.stockstatuscd=3;
      this.warehouseService
    .GetWareHouseLocSlotsWHId(this.productionExecutionData?.whid)
   .subscribe((Data) => {
    this.updatedwhslotdata=Data.filter(x=>x.locationId==this.productionExecutionData.productionExecutionDetails[0].storagelocationID);
        console.log('this.updatedwhslotdata',this.updatedwhslotdata) 
      })
    }




     console.log(' this.productionExecutionData.productionExecutionDetails', this.productionExecutionData.productionExecutionDetails)
this.locationSelected=this.productionExecutionData.productionExecutionDetails[0].storagelocationID;
   //  this.SlotsbyWhidandLocation(this.productionExecutionData?.whid,this.productionExecutionData.productionExecutionDetails[0].storagelocationID);

   this.warehouseService
    .GetWareHouseLocSlotsWHIdandLocationID(this.productionExecutionData?.whid,this.locationSelected)
    .subscribe((data) => {
      this.locationslots = data;
      
   
     console.log(' this.locationslots.locationslots', this.locationslots)
   
        this.locationslots.forEach((slotdata) => {

          console.log('stocksheetList',this.tempStocksheetList);
          console.log('slotdata',slotdata);
          this.productionExecutionData.productionExecutionDetails.forEach((slottbwdata) => {
            console.log('3333333333', slottbwdata.transNo);
           
            this.warehouseService
            .GetAllStockByProductAndStatus(this.productionExecutionData?.whid,slottbwdata.productCd,this.stockstatuscd)
            .subscribe(
              (data) => {
             
               
                this.tempStocksheetList = data;
               
                   console.log('stocksheetListtemp', this.tempStocksheetList);

                   this.stocksheetList= this.tempStocksheetList.filter(c=>c.slotId==slottbwdata.slotId && c.productCd==slottbwdata.productCd && c.statusCd==this.stockstatuscd);
                   console.log('stocksheetList', this.stocksheetList);
                   if(this.stocksheetList.length>0)
                   {
                    this.instockqty=this.stocksheetList[0].inStockQty;
                   }
          if(slottbwdata.moveTypeD==slotdata.slotId)
          {
            if(this.productionOrderdetails.length>0)
            {
              this.ispresent=this.productionOrderdetails.filter(x=>x.slotId==slottbwdata.moveTypeD)
            }
            let rfu=0;
            let tbw=0;
          if(slottbwdata.statusCd==1)
          {
            rfu=slottbwdata.productionQty
          }
           else if(slottbwdata.statusCd==21)
           {
            tbw=slottbwdata.productionQty
           }
            if( this.ispresent.length==0)
            {
                 //   this.instockqty=slottbwdata.productionQty;
                   //}
                      let slotdetails: ProductionOrderDetails=
                      {          
                        productCd: slottbwdata.productCd,
                        productName:slottbwdata.productName,
                        userQty:slottbwdata.productionQty,
                        slotId:slotdata.slotId,
                        locationSlotNo:slotdata.locationSlotNo,
                        fromslotid:slottbwdata.transNo,
                        capacity:slotdata.capacity,
                        locationId:slotdata.locationID,
                   rfu:rfu,
                   tbr:0,
                   tbhw:0,
                   tbw:tbw,
                   scrp:0,
                   statuscd:0,
                   
                        locationName:slotdata.locationName,
                        insqty:this.instockqty,
                      }   
          
                      this.productionOrderdetails.push(slotdetails);
                    }
                  }

              
              });
            
          

          })

        
           })
           console.log(' this.productionOrderdetails.productionOrderdetails', this.productionOrderdetails)
           this.loading=false;   
          });
   }
   
        },
        (err) => {
          console.error(err);
        }
      );
  }
  onWarehouseChange(event: MatSelectChange) {
    if (event.value) {
      this.warehouseService
        .WarehouseLocationSelectionByWhId(event.value)
        .subscribe((data) => {
          this.storageLocations = data;
        });
    } else {
      this.storageLocations = [];
    }
  }
  SlotsbyWhidandLocation(whid:number,locationid:number) {
  
   
    this.warehouseService
    .GetWareHouseLocSlotsWHIdandLocationID(whid,locationid)
    .subscribe((data) => {
      this.locationslots = data;
      
    });

    console.log('this.locationslots',this.locationslots);
   
  }
  AddNewSlotForRepairProcess(slotid:any) {
    debugger;
    let filterStatuswise;
    let vm = this;
    this.loading = true;
    
    for (let index = 0; index < slotid.length; index++) {
    const getslotname = this.locationslots.filter(
      (x) => x.slotId === slotid[index]
      );
   
let ispresent=this.productionOrderdetails.filter(
  (x) => x.slotId === slotid[index]
  );
  console.log('ispresents',ispresent);
  if(ispresent.length==0)
  {
          let slotdetails: ProductionOrderDetails=
          {          
            productCd: this.productionOrderdetails[0].productCd,
            productName:this.productionOrderdetails[0].productName,
            userQty:this.productionOrderdetails[0].insqty,
            slotId:slotid[index],
            locationSlotNo:getslotname[0].locationSlotNo,
            fromslotid:this.productionOrderdetails[0].slotId,
            capacity:0,
            locationId: this.washForm.get('locationName').value,
       rfu:0,
       tbr:0,
       tbhw:0,
       tbw:0,
       scrp:0,
       statuscd:0,
       
            locationName:this.washForm.get('locationName').value,
            insqty:this.productionOrderdetails[0].insqty,
          }
         // this.productionOrderdetails.push(slotdetails)


          this.productionOrderdetails.push(slotdetails);
          filterStatuswise = undefined;
      
          
          console.log('getslotname',getslotname);
          }
          else
          {
            this.dialog.open(MessageDailogComponent, {
              width: '380px',
              maxWidth: '100%',
              data: {
                title: 'Slot ',
                messege:
                  'Steckplatz bereits vorhanden',
              },
            });
          }
   
    this.loading = false;
        }
    console.log('this.productionOrderdetails.push',this.productionOrderdetails)
  }



  UpdateRepair(event) {
    debugger;
console.log('this.washForm.getvalue',this.washForm.get('employee').value);

     const requestBodyUpdate = {
       productionExecutionId: this.documentid,
     productionExecutionDate: this.datePipe.transform(
      this.washForm.get('washDate').value,
      'yyyy-MM-dd'
    ),
   
     completionDate:  this.datePipe.transform(
      this.washForm.get('washDate').value,
      'yyyy-MM-dd'
    ),
   
       remarks: "",
      movementId: 1,
      transTypeId: 1,
      whid: this.washForm.get('warehouseName').value,
      empId: this.washForm.get('employee').value,
       machineCd:this.washForm.get('machine').value,
     productionExecutionDetails: [],
     };

     console.log('this.productionOrderdetails',this.productionOrderdetails);
    this.productionOrderdetails.forEach((element) => {
      if (element.rfu > 0) {
        var filtered=requestBodyUpdate.productionExecutionDetails.filter(x=>x.slotId==element.slotId)
        if(filtered.length>0)
        {
          requestBodyUpdate.productionExecutionDetails[0].tbwQty=element.rfu
        }
        else
        {
          requestBodyUpdate.productionExecutionDetails.push({
          id: 0,
          sortingId: 0,
          productCd: element.productCd,
          prevStatusCd: 21,
          statusCd: 1,   
          rfuQty: Number(element.rfu),
          tbwQty: Number(element.tbw),
          tbrQty: Number(element.tbr),
          tbhwQty: Number(element.tbhw),
          scrapQty: Number(element.scrp),
          timeStart: this.timefrom.nativeElement.value,
          timeEnd: this.timeto.nativeElement.value,
         
          slotId:element.fromslotid,//Here fromslotid given for stock update
          MoveTypeD:element.slotId,//Here toslotid given for stock update
          timeTaken: element.slotId,
         
          storageLocationId:  this.washForm.get('locationName').value,
      
          productionOrderDetails: [
            {
              productionId: this.productionData?.id,
            },
          ],
    }) 
  } 
  }
      if (element.tbw > 0) {
        var filtered=requestBodyUpdate.productionExecutionDetails.filter(x=>x.slotId==element.slotId)
        if(filtered.length>0)
        {
          requestBodyUpdate.productionExecutionDetails[0].tbwQty=element.tbw
        }
        else
        {
          requestBodyUpdate.productionExecutionDetails.push({
          id: 0,
          sortingId: 0,
          productCd: element.productCd,
          prevStatusCd: 21,
          statusCd: 21,   
          rfuQty: Number(element.rfu),
          tbwQty: Number(element.tbw),
          tbrQty: Number(element.tbr),
          tbhwQty: Number(element.tbhw),
          scrapQty: Number(element.scrp),
          timeStart: this.timefrom.nativeElement.value,
          timeEnd: this.timeto.nativeElement.value,
         
          slotId:element.fromslotid,//Here fromslotid given for stock update
          MoveTypeD:element.slotId,//Here toslotid given for stock update
          timeTaken: element.slotId,
         
          storageLocationId:  this.washForm.get('locationName').value,
      
          productionOrderDetails: [
            {
              productionId: this.productionData?.id,
            },
          ],
    }) 
  } 
  }


  if (element.tbr > 0) {
    var filtered=requestBodyUpdate.productionExecutionDetails.filter(x=>x.slotId==element.slotId)
    if(filtered.length>0)
    {
      requestBodyUpdate.productionExecutionDetails[0].tbrQty=element.tbr
    }
    else
    {
      requestBodyUpdate.productionExecutionDetails.push({
      id: 0,
          sortingId: 0,
          productCd: element.productCd,
          prevStatusCd: 21,
          statusCd: 3,
          rfuQty: Number(element.rfu),
          tbwQty: Number(element.tbw),
          tbrQty: Number(element.tbr),
          tbhwQty: Number(element.tbhw),
          scrapQty: Number(element.scrp),
          timeStart: this.timefrom.nativeElement.value,
          timeEnd: this.timeto.nativeElement.value,
          slotId:element.fromslotid,//Here fromslotid given for stock update
          MoveTypeD:element.slotId,//Here toslotid given for stock update
        //  timeTaken: this.stockByProductData[0].slotId,
          //storageLocationId: this.newStorageLocationValue,
          storageLocationId:  this.washForm.get('locationName').value,
          timeTaken: element.slotId,
         // timeStart: this.timefrom.nativeElement.value,
          //timeEnd: this.timeto.nativeElement.value,
          // timeTaken: this.getTimeDifference(this.timefrom.nativeElement.value, this.timeto.nativeElement.value),
          productionOrderDetails: [
            {
              productionId: this.productionData?.id,
            },
          ],
    })  
  }
  }
  if (element.tbhw > 0) {
    var filtered=requestBodyUpdate.productionExecutionDetails.filter(x=>x.slotId==element.slotId)
    if(filtered.length>0)
    {
      requestBodyUpdate.productionExecutionDetails[0].tbhwQty=element.tbhw
    }
    else
    {
      requestBodyUpdate.productionExecutionDetails.push({
      id: 0,
          sortingId: this.productionData?.id,
          productCd: this.productionData?.productionOrderDetails[0].productCd,
          prevStatusCd: 21,
          statusCd: 22,
          rfuQty: Number(element.rfu),
          tbwQty: Number(element.tbw),
          tbrQty: Number(element.tbr),
          tbhwQty: Number(element.tbhw),
          scrapQty: Number(element.scrp),
          timeStart: this.timefrom.nativeElement.value,
          timeEnd: this.timeto.nativeElement.value,
          slotId:element.fromslotid,//Here fromslotid given for stock update
          MoveTypeD:element.slotId,//Here toslotid given for stock update
          
          timeTaken: element.slotId,
       
        
          storageLocationId:  this.washForm.get('locationName').value,
          // timeStart: this.timefrom.nativeElement.value,
          // timeEnd: this.timeto.nativeElement.value,
          // timeTaken: this.getTimeDifference(this.timefrom.nativeElement.value, this.timeto.nativeElement.value),
          productionOrderDetails: [
            {
              productionId: this.productionData?.id,
            },
          ],
    })  
  }
  }
  })

    

    // this.productionExecutionId
    //   ? this.UpdateProductionWash(requestBodyUpdate):
    // alert()
    console.log(requestBodyUpdate);
    this.UpdateProductionRepair(requestBodyUpdate);
  }





  UpdateProductionRepair(body) {
    this.loading=true;

console.log('body',body);
 

    this.productionService.UpdateProductionWash(body).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.update == true) {
          const message = data.message;
          this.snackBar.open(message, 'Close', {
            duration: 10000,
          });
          var myWindow = window.open(
            '',
            '',
            'width=1000,height=1125,scrollbars=yes,resizable=yes'
          );
          myWindow.document.write(data.htmlData);
          myWindow.document.close();
          myWindow.focus();
          myWindow.print();
          setTimeout(() => {
            myWindow.close();
          }, 10000);
          let currentUrl = "/production/repair";
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([currentUrl]);
                  this.dialogRef.close();
              });  
        }
        else {
          this.dialog.open(MessageDailogComponent, {
            width: '380px',
            maxWidth: '100%',
            data: {
              title: 'Update Repair',
              messege:
                'We are having an issue to accept your request, please try again later !',
            },
          });
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
}
