
<div class="mb-2">
 
    <div class="row">
                    
        <div class="col-md-8">
          <h4 class="p-3 text-left">   {{ "common.document" | translate }} : {{productionExecutionData?.productionExecutionNo

            }}</h4>

          </div>
        </div>
    <div class="row">
      
    
      <div class="col-12">
       
          
            <div class="card pl-4 pr-4">
              <form
              #washFormTemplate="ngForm"
              (isValid)="UpdateRepair($event)"
              [formGroup]="washForm"
              autocomplete="off"
            >
              >
             
          
              
             
                
              
              <pp-loading
           
              [message]="'common.loading' | translate"
            ></pp-loading>
            <pp-loading
              
              [message]="'common.processing' | translate"
            ></pp-loading>
                <div class="mb-3">
                
                    <div class="col-md-14">     
                      <div class="row sm-gutters">
                        <div class="row sm-gutters">               
                         
                        
  
                    
  
                            <mat-form-field
                            appearance="outline"
                            class="col-md-2"
                          >
                            <mat-label>
                              {{ "common.warehouse" | translate }}
                            </mat-label>
                            <mat-select
                              [error]="warehouseName"
                              formControlName="warehouseName"
                              [attr.name]="
                                'common.business-partner' | translate
                              "
                            >
                              <mat-option>None</mat-option>
                              <mat-option
                                *ngFor="let wh of warehouse"
                                [value]="wh.whid"
                              >
                                {{ wh.whname }}
                              </mat-option>
                            </mat-select>
                            <mat-error #warehouseName></mat-error>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="col-md-2" >
                            <mat-label>{{ "common.storage-location" | translate }}</mat-label>
                            <mat-select formControlName="locationName" [(value)]="locationSelected" (selectionChange)="onStorageLocationChange($event)">
                              <mat-option
                                *ngFor="let loc of storageLocations"
                                [value]="loc.id"
                              >
                                {{ loc.locationName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="col-md-1">
                            <mat-label>Slot Nr</mat-label>
                            <mat-select #select 
                            formControlName="Slotno"
                              multiple
                              [(ngModel)]="isChecked"
                              spellcheck="false"
                              (selectionChange)="OnSlotNoChange($event)"
                            >
                              <mat-option
                              
                                *ngFor="let status of locationslots"
                            
                                [value]="status.slotId"
                              >
                                {{ status.locationSlotNo }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                            <mat-form-field
                              appearance="outline"
                              class="col-md-2"
                            >
                              <mat-label>Repair Date</mat-label>
                              <input
                                [error]="washDate"
                                placeholder="MM/DD/YYYY"
                                formControlName="washDate"
                                matInput
                                [name]="'dsp.order-date' | translate"
                                [matDatepicker]="washDatePicker"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="washDatePicker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #washDatePicker></mat-datepicker>
                              <mat-error #washDate></mat-error>
                            </mat-form-field>
                         

                        

                            <mat-form-field
                            appearance="outline"
                            class="col-md-2"
                          >
                            <mat-label>
                              {{ "prod.machine" | translate }}
                            </mat-label>
                            <mat-select
                         
                              name="machine"
                              formControlName="machine"
                            >
                              <mat-option value=""></mat-option>
                              <mat-option
                                *ngFor="let mc of machineDD"
                                [value]="mc.machineCd"
                              >
                                {{ mc.machineD }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field
                            appearance="outline"
                            class="col-md-2"
                          >
                            <mat-label>
                              {{ "prod.employee" | translate }}
                            </mat-label>
                            <mat-select
                              name="employee"
                              formControlName="employee"
                            >
                              <mat-option value=""></mat-option>
                              <mat-option
                                *ngFor="let emp of employeeDD"
                                [value]="emp.empId"
                              >
                                {{ emp.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                      
                          <div
                          class="table-responsive"
                        
                        >
                          <table
                            class="table table-bordered table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th>{{ "common.product" | translate }}</th>
                                <th>{{ "common.quantity" | translate }}</th>
                                 <th>
                                  {{
                                    "common.toslot" | translate
                                  }}
                                </th>

                            
                                <th>{{ "common.rfu" | translate }}</th>
                                <th>{{ "common.tbw" | translate }}</th>
                                <th>{{ "common.tbr" | translate }}</th>
                                <th>{{ "common.tbhw" | translate }}</th>
                                <th>{{ "common.scrap" | translate }}</th>
                                <th>
                                  {{ "common.balancequantity" | translate }}
                                </th>
                                <th>{{ "common.timefrom" | translate }}</th>
                                <th>{{ "common.timeto" | translate }}</th>
                                <th>
                                  {{ "common.timedifference" | translate }}
                                </th>
                              </tr>
                            </thead>
                            <tbody class="table-data">
                              <tr class="bg-light" *ngFor="let data of productionOrderdetails;let i = index">
                                <td
                                  style="
                                    min-width: 180px;
                                    vertical-align: middle;
                                  "
                                >
                                {{ data.productName }}
                                </td>
                                <td style="vertical-align: middle">
                                 {{
                                  data.insqty
                                 }}
                                </td>
                                <td style="vertical-align: middle">
                                  {{ data.locationSlotNo }}
                                  
                                </td>
                                <td>

                                  <mat-form-field
                                  appearance="outline"
                                  style="max-width: 100px; padding: 0"
                                  
                                >
                                  <input
                                    #rfu
                                  
                                 
                                    matInput
                                    [name]="'common.rfu' | translate"
                                   [value]="data.rfu"
                                   [disabled]="disablerfu"
                                  />
                                </mat-form-field>


                                 
                                </td>
                                <td>

                                  <mat-form-field
                                  appearance="outline"
                                  style="max-width: 100px; padding: 0"
                                >
                                  <input
                                    #tbw
                                  
                                 
                                    matInput
                                    [name]="'common.tbw' | translate"
                                   [value]="data.tbw"
                                  />
                                </mat-form-field>


                                 
                                </td>
                                <td>
                                  <mat-form-field
                                    appearance="outline"
                                    style="max-width: 100px; padding: 0"
                                  >
                                    <input
                                      #tbr
                                    
                                      [placeholder]="
                                      'common.tbr' | translate
                                    "
                                      matInput
                                      [name]="'common.tbr' | translate"
                                      type="number"
                                    />
                                  </mat-form-field>
                                </td>
                                <td>
                                  <mat-form-field
                                    appearance="outline"
                                    style="max-width: 100px; padding: 0"
                                  >
                                    <input
                                      #tbhw
                                     
                                      [placeholder]="
                                        'common.tbhw' | translate
                                      "
                                      matInput
                                      [name]="'common.tbhw' | translate"
                                      type="number"
                                    />
                                  </mat-form-field>
                                </td>
                                <td>
                                  <mat-form-field
                                    appearance="outline"
                                    style="max-width: 100px"
                                  >
                                    <input
                                      #scrap
                                      (input)="validateBalanceQty()"
                                      [placeholder]="
                                        'common.scrap' | translate
                                      "
                                      matInput
                                      [name]="'common.scrap' | translate"
                                      type="number"
                                    />
                                  </mat-form-field>
                                </td>
                                <td
                                 
                                >
                                 
                                </td>
                                <td>

                               
                              
                                  <mat-form-field
                                  
                                  >
                                  <input matInput [matTimepicker]="picker"
                                      #timefrom
                                      type="time"
                                    
                                      [name]="'common.timefrom'"
                                    />
                                  </mat-form-field>
                                </td>
                                <td>
                                  <mat-form-field
                                   
                                    style="max-width: 150px"
                                  >
                                    <input
                                      #timeto
                                      type="time"
                                      matInput
                                      [name]="'common.timeto'"
                                    />
                                  </mat-form-field>
                                </td>
                                <td style="vertical-align: middle">
                                 
                                </td>
                                <td class="text-right">
                       
                                  <button
                                   
                                    type="button"
                                    mat-icon-button
                                    color="warn"
                                    title="Löschen"
                                    
                                    aria-label="delete"
                                  >
                                    <mat-icon>delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
  
                    
  
                    
                      
                       
                      </div>
                    </div>
                  </div>
  
                 
  
                
  
  
                  
                  <div class="table-responsive" style="width: 100%">
                 
                  </div>
  
                  <div class="text-right">
                    <button
                      class="btn-pp"
                      type="submit"
                      color="accent"
                     
                      mat-raised-button
                    >
                      {{ 'Update' | translate }}
                    </button>
                    &nbsp;
                    <button
                      class="btn-pp"
                      type="button"
                      color="accent"
                      mat-raised-button
                      mat-dialog-close
                     
                    >
                      {{ 'common.close' | translate }}
                    </button>
                  </div>
                </div>
                <div class="float-right"></div>
              </form>
             
              
            </div>
          
     
      </div>
    </div>
       
  </div>
  