import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './component/layout/layout.component';
import { AuthGuard } from './guards/auth/auth.guard';

import { AppModuleLanguageResolver } from './app-module-language.resolver';
import { AuthModuleLanguageResolver } from './component/auth/auth-module-language.resolver';
import { PurchaseModuleLanguageResolver } from './component/purchase/purchase-module-language.resolver';
import { WarehouseModuleLanguageResolver } from './component/warehouse/warehouse-module-language.resolver';
import { ProductionModuleLanguageResolver } from './component/production/production-module-language.resolver';
import { WarehouseLayoutComponent } from './component/layout/warehouse-layout/warehouse-layout.component';
import { SldashboradComponent } from './sldashborad/sldashborad.component';
import { SlalldetaildialogComponent } from './sldashborad/sldetail/slalldetaildialog/slalldetaildialog.component';
import { OpeningStockCorrectionListModule } from './component/warehouse/opening-stock-correction/opening-stock-correction-list/opening-stock-correction-list.module';
import { ProductionpartreportModule } from './component/productionpartreport/productionpartreport.module';
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./component/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {path:'pp-sldashborad',component:SldashboradComponent},
      {path:'pp-slalldetaildialog',component:SlalldetaildialogComponent},
      //Product Master
      {
        path: 'master/product/create',
        loadChildren: () =>
          import(
            './component/master/product/product-create/product-create.module'
          ).then((m) => m.ProductCreateModule),
      },
      {
        path: 'master/product',
        loadChildren: () =>
          import(
            './component/master/product/product-list/product-list.module'
          ).then((m) => m.ProductListModule),
      },
      //{ path: 'master/product/edit/:id', loadChildren: () => import('./component/master/product/product-update/product-update.module').then(m => m.ProductUpdateModule) },
      {
        path: 'master/product/:id',
        loadChildren: () =>
          import(
            './component/master/product/product-create/product-create.module'
          ).then((m) => m.ProductCreateModule),
      },
      // //BusinessPartner Master
      {
        path: 'master/business-partner/create',
        loadChildren: () =>
          import(
            './component/master/business-partner/business-partner-create/business-partner-create.module'
          ).then((m) => m.BusinessPartnerCreateModule),
      },
      {
        path: 'master/business-partner',
        loadChildren: () =>
          import(
            './component/master/business-partner/business-partner-list/business-partner-list.module'
          ).then((m) => m.BusinessPartnerListModule),
      },
      {
        path: 'master/business-partner/:id',
        loadChildren: () =>
          import(
            './component/master/business-partner/business-partner-create/business-partner-create.module'
          ).then((m) => m.BusinessPartnerCreateModule),
      },

      //Master => Route
      {
        path: 'master/machine',
        loadChildren: () =>
          import(
            './component/master/machine/machine-list/machine-list.module'
          ).then((m) => m.MachineListModule),
      },
      {
        path: 'master/changepass',
        loadChildren: () =>
          import(
            './component/master/changepassword/changepassword.module'
          ).then((m) => m.ChangepasswordModule),
      },
      {
        path: 'profile-edit',
        loadChildren: () =>
          import(
            './component/master/company/company-profile-edit/company-profile-edit.module'
          ).then((m) => m.CompanyProfileEditModule),
      },
      {
        path: 'master/profile-edit',
        loadChildren: () =>
          import(
            './component/master/company/company-profile-edit/company-profile-edit.module'
          ).then((m) => m.CompanyProfileEditModule),
      },
      {
        path: 'master/user-list',
        loadChildren: () =>
          import(
            './component/master/company/user/user-list/user-list.module'
          ).then((m) => m.UserListModule),
      },
      //Master => Vehicle
      {
        path: 'master/vehicle',
        loadChildren: () =>
          import(
            './component/master/vehicle/vehicle-list/vehicle-list.module'
          ).then((m) => m.VehicleListModule),
      },
      //Master => Route
      {
        path: 'master/route',
        loadChildren: () =>
          import('./component/master/route/route-list/route-list.module').then(
            (m) => m.RouteListModule
          ),
      },

      //Master => Warehouse
      {
        path: 'master/warehouse',
        loadChildren: () =>
          import(
            './component/master/warehouse/warehouse-list/warehouse-list.module'
          ).then((m) => m.WarehouseListModule),
      },

      //Master => reasoncode
      {
        path: 'master/reasoncode',
        loadChildren: () =>
          import(
            './component/master/reasoncode/reasoncode-list/reasoncode-list.module'
          ).then((m) => m.ReasoncodeListModule),
      },

      //Master => productstatus
      {
        path: 'master/productstatus',
        loadChildren: () =>
          import(
            './component/master/productstatus/productstatus-list/productstatus-list.module'
          ).then((m) => m.ProductstatusListModule),
      },

      //Master => productgroup
      {
        path: 'master/productgroup',
        loadChildren: () =>
          import(
            './component/master/productgroup/productgroup-list/productgroup-list.module'
          ).then((m) => m.ProductgroupListModule),
      },

      //Master => producttype
      {
        path: 'master/producttype',
        loadChildren: () =>
          import(
            './component/master/producttype/producttype-list/producttype-list.module'
          ).then((m) => m.ProducttypeListModule),
      },

      //Master => reasoncode
      {
        path: 'master/local-resource',
        loadChildren: () =>
          import(
            './component/master/local-resource/local-resource-list/local-resource-list.module'
          ).then((m) => m.LocalResourceListModule),
      },
      //Master => state
      {
        path: 'master/state',
        loadChildren: () =>
          import('./component/master/state/state-list/state-list.module').then(
            (m) => m.StateListModule
          ),
      },

      //Master => country
      {
        path: 'master/country',
        loadChildren: () =>
          import(
            './component/master/country/country-list/country-list.module'
          ).then((m) => m.CountryListModule),
      },

      //Master => department
      {
        path: 'master/department',
        loadChildren: () =>
          import(
            './component/master/department/department-list/department-list.module'
          ).then((m) => m.DepartmentListModule),
      },

      //Master => employee
      {
        path: 'master/employee',
        loadChildren: () =>
          import(
            './component/master/employee/employee-list/employee-list.module'
          ).then((m) => m.EmployeeListModule),
      },

      {
        path: 'master/product/edit/:id',
        loadChildren: () =>
          import(
            './component/master/product/product-list/product-list.module'
          ).then((m) => m.ProductListModule),
      },

      //Purchase => Purchase Order
      {
        path: 'purchase/order/create',
        loadChildren: () =>
          import(
            './component/purchase/purchase-order/purchase-order.module'
          ).then((m) => m.PurchaseOrderModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/order',
        loadChildren: () =>
          import(
            './component/purchase/purchase-order/purchase-order-list/purchase-order-list.module'
          ).then((m) => m.PurchaseOrderListModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/order/edit/:id',
        loadChildren: () =>
          import(
            './component/purchase/purchase-order/purchase-order-update/purchase-order-update.module'
          ).then((m) => m.PurchaseOrderUpdateModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      //Purchase =>  purchase requisition
      {
        path: 'purchase/requisition/create',
        loadChildren: () =>
          import(
            './component/purchase/purchase-requisition/purchase-requisition-insert/purchase-requisition-insert.module'
          ).then((m) => m.PurchaseRequisitionInsertModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/requisition',
        loadChildren: () =>
          import(
            './component/purchase/purchase-requisition/purchase-requisition-list/purchase-requisition-list.module'
          ).then((m) => m.PurchaseRequisitionListModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      //{ path: 'purchase/requisition/view/:id', loadChildren: () => import('./component/purchase/purchase-requisition/purchase-requisition-view/purchase-requisition-view.module').then(m => m.PurchaseRequisitionViewModule) },
      {
        path: 'purchase/requisition/edit/:id',
        loadChildren: () =>
          import(
            './component/purchase/purchase-requisition/purchase-requisition-update/purchase-requisition-update.module'
          ).then((m) => m.PurchaseRequisitionUpdateModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/requisition/repeat/:id/:mode',
        loadChildren: () =>
          import(
            './component/purchase/purchase-requisition/purchase-requisition-update/purchase-requisition-update.module'
          ).then((m) => m.PurchaseRequisitionUpdateModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      //Purchase => request for quotation
      {
        path: 'purchase/request-for-quotation/create',
        loadChildren: () =>
          import(
            './component/purchase/request-for-quotation/request-for-quotation-insert/request-for-quotation-insert.module'
          ).then((m) => m.RequestForQuotationInsertModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/request-for-quotation',
        loadChildren: () =>
          import(
            './component/purchase/request-for-quotation/request-for-quotation-list/request-for-quotation-list.module'
          ).then((m) => m.RequestForQuotationListModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/request-for-quotation/edit/:id',
        loadChildren: () =>
          import(
            './component/purchase/request-for-quotation/request-for-quotation-update/request-for-quotation-update.module'
          ).then((m) => m.RequestForQuotationUpdateModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/request-for-quotation/repeat/:id/:mode',
        loadChildren: () =>
          import(
            './component/purchase/request-for-quotation/request-for-quotation-update/request-for-quotation-update.module'
          ).then((m) => m.RequestForQuotationUpdateModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      //Purchase => vendor offer
      {
        path: 'purchase/stock-level',
        loadChildren: () =>
          import('./component/purchase/stock-level/stock-level.module').then(
            (m) => m.StockLevelModule
          ),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/vendor-offer/create',
        loadChildren: () =>
          import(
            './component/purchase/vendor-offer/vendor-offer-insert/vendor-offer-insert.module'
          ).then((m) => m.VendorOfferInsertModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/vendor-offer',
        loadChildren: () =>
          import(
            './component/purchase/vendor-offer/vendor-offer-list/vendor-offer-list.module'
          ).then((m) => m.VendorOfferListModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },
      {
        path: 'purchase/vendor-offer/edit/:id',
        loadChildren: () =>
          import(
            './component/purchase/vendor-offer/vendor-offer-update/vendor-offer-update.module'
          ).then((m) => m.VendorOfferUpdateModule),
        resolve: { lang: PurchaseModuleLanguageResolver },
      },

      //Logistics => order process
      {
        path: 'logistics/order/create',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      
      {
        path: 'logistics/multicreateor',
        loadChildren: () =>
          import(
            './component/logistics/order-process/multi-order-insert/multi-order-insert.module'
          ).then((m) => m.MultiOrderInsertModule),
      },
      {
        path: 'logistics/multicreatecr',
        loadChildren: () =>
          import(
            './component/logistics/order-process/multi-order-insert/multi-order-insert.module'
          ).then((m) => m.MultiOrderInsertModule),
      },
      {
        path: 'logistics/order',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-list/order-process-list.module'
          ).then((m) => m.OrderProcessListModule),
      },
      {
        path: 'logistics/ordercreate',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      {
        path: 'logistics/order/edit/:id',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      {
        path: 'logistics/order/repeat/:id',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      // Logistics => Collection Order
      {
        path: 'logistics/collection-order/create',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      }, {
        path: 'logistics/collection-ordercreate',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      {
        path: 'logistics/collection-order',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-list/order-process-list.module'
          ).then((m) => m.OrderProcessListModule),
      },
      {
        path: 'logistics/collection-order/edit/:id',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      {
        path: 'logistics/collection-order/repeat/:id',
        loadChildren: () =>
          import(
            './component/logistics/order-process/order-process-insert/order-process-insert.module'
          ).then((m) => m.OrderProcessInsertModule),
      },
      // Logistics => Dispatch Plan
      {
        path: 'logistics/dispatch-plan/create',
        loadChildren: () =>
          import(
            './component/logistics/dispatch-plan/dispatch-plan-insert/dispatch-plan-insert.module'
          ).then((m) => m.DispatchPlanInsertModule),
      },
      {
        path: 'logistics/dispatch-plancreate',
        loadChildren: () =>
          import(
            './component/logistics/dispatch-plan/dispatch-plan-insert/dispatch-plan-insert.module'
          ).then((m) => m.DispatchPlanInsertModule),
      },
      {
        path: 'logistics/dispatch-plan',
        loadChildren: () =>
          import(
            './component/logistics/dispatch-plan/dispatch-plan-list/dispatch-plan-list.module'
          ).then((m) => m.DispatchPlanListModule),
      },
      {
        path: 'logistics/dispatch-plan/edit/:id',
        loadChildren: () =>
          import(
            './component/logistics/dispatch-plan/dispatch-plan-insert/dispatch-plan-insert.module'
          ).then((m) => m.DispatchPlanInsertModule),
      },
      // Logistics => Shipment Order
      {
        path: 'logistics/shipment-order/create',
        loadChildren: () =>
          import(
            './component/logistics/shipment-order/shipment-order-insert/shipment-order-insert.module'
          ).then((m) => m.ShipmentOrderInsertModule),
      },
      {
        path: 'logistics/shipment-ordercreate',
        loadChildren: () =>
          import(
            './component/logistics/shipment-order/shipment-order-insert/shipment-order-insert.module'
          ).then((m) => m.ShipmentOrderInsertModule),
      },
      {
        path: 'logistics/shipment-order',
        loadChildren: () =>
          import(
            './component/logistics/shipment-order/shipment-order-list/shipment-order-list.module'
          ).then((m) => m.ShipmentOrderListModule),
      },
      {
        path: 'logistics/shipment-order/edit/:id',
        loadChildren: () =>
          import(
            './component/logistics/shipment-order/shipment-order-insert/shipment-order-insert.module'
          ).then((m) => m.ShipmentOrderInsertModule),
      },
      //Warehouse => Goods recieve

      {
        path: 'warehouse/goods-receive',
        loadChildren: () =>
          import(
            './component/warehouse/goods-receive/goods-receive-insert/goods-receive-insert.module'
          ).then((m) => m.GoodsReceiveInsertModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      {
        path: 'warehouse/goods-issue',
        loadChildren: () =>
          import(
            './component/warehouse/goods-issue/goods-issue-insert/goods-issue-insert.module'
          ).then((m) => m.GoodsIssueInsertModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      {
        path: 'warehouse/opening-stock',
        loadChildren: () =>
          import(
            './component/warehouse/opening-stock/opening-stock-insert/opening-stock-insert.module'
          ).then((m) => m.OpeningStockInsertModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      {
        path: 'warehouse/opening-stock-correction',
        loadChildren: () =>
          import(
            './component/warehouse/opening-stock-correction/opening-stock-correction-insert/opening-stock-correction-insert.module'
          ).then((m) => m.OpeningStockCorrectionInsertModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      {
      path: 'warehouse/stockcorrection-list',
        loadChildren: () =>
          import(
            './component/warehouse/opening-stock-correction/opening-stock-correction-list/stock-correction-list.component'
          ).then((m) => OpeningStockCorrectionListModule),
      
      },
      {
        path: 'warehouse/opening-stock-sheet',
        loadChildren: () =>
          import(
            './component/warehouse/opening-stock-sheet/opening-stock-sheet-list/opening-stock-sheet-list.module'
          ).then((m) => m.OpeningStockSheetListModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      {
        path: 'warehouse/warehouse-inventory',
        loadChildren: () =>
          import(
            './component/warehouse/warehouse-inventory/warehouse-inventory-entry/warehouse-inventory-entry.module'
          ).then((m) => m.WareHouseInventoryEntryModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },

      

      //Production => Production Forcast
      {
        path: 'production/production-forcast',
        loadChildren: () =>
          import(
            './component/production/production-forcast/production-forcast-insert/production-forcast-insert.module'
          ).then((m) => m.ProductionForcastInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/planning',
        loadChildren: () =>
          import('./component/production/planing/planing.module').then(
            (m) => m.PlaningModule
          ),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      
      {
        path: 'production/todaypl',
        loadChildren: () =>
          import('./component/production/planing/planing.module').then(
            (m) => m.PlaningModule
          ),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/planning/list/edit/:id',
        loadChildren: () =>
          import(
            './component/production/planing/planing-update/planing-update.module'
          ).then((m) => m.PlaningUpdateModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/report',
        loadChildren: () =>
          import('./component/productionpartreport/productionpartreport.component').then(
            (m) =>m.ProductionpartreportComponent
          ),
        
      },
      // {
      //   path: 'production/inspection/edit/:id',
      //   loadChildren: () =>
      //     import(
      //       './component/production/product-inspection/product-inspection-insert/product-inspection-insert.module'
      //     ).then((m) => m.ProductInspectionInsertModule),
      //   resolve: { lang: ProductionModuleLanguageResolver },
      // },

      //Production Wash
      {
        path: 'production/wash',
        loadChildren: () =>
          import('./component/production/wash/wash.module').then(
            (m) => m.WashModule
          ),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/wash/wash-create',
        loadChildren: () =>
          import(
            './component/production/wash/wash-create/wash-create.module'
          ).then((m) => m.WashCreateModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/washcreate',
        loadChildren: () =>
          import(
            './component/production/wash/wash-create/wash-create.module'
          ).then((m) => m.WashCreateModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      //Production Repair
      {
        path: 'production/repair',
        loadChildren: () =>
          import(
            './component/production/repair/repair-list/repair-list.module'
          ).then((m) => m.RepairListModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },

      {
        path: 'production/repair/repair-create',
        loadChildren: () =>
          import(
            './component/production/repair/repair-create/repair-create.module'
          ).then((m) => m.RepairCreateModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/repaircreate',
        loadChildren: () =>
          import(
            './component/production/repair/repair-create/repair-create.module'
          ).then((m) => m.RepairCreateModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },

      //Production => Product Inspection
      {
        path: 'production/inspection/create',
        loadChildren: () =>
          import(
            './component/production/product-inspection/product-inspection-insert/product-inspection-insert.module'
          ).then((m) => m.ProductInspectionInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/inspectioncreate',
        loadChildren: () =>
          import(
            './component/production/product-inspection/product-inspection-insert/product-inspection-insert.module'
          ).then((m) => m.ProductInspectionInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/inspection/edit/:id',
        loadChildren: () =>
          import(
            './component/production/product-inspection/product-inspection-insert/product-inspection-insert.module'
          ).then((m) => m.ProductInspectionInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/inspection',
        loadChildren: () =>
          import(
            './component/production/product-inspection/product-inspection-list/product-inspection-list.module'
          ).then((m) => m.ProductInspectionListModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/sorting/create',
        loadChildren: () =>
          import(
            './component/production/sorting/sorting-insert/sorting-insert.module'
          ).then((m) => m.SortingInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/sortingcreate',
        loadChildren: () =>
          import(
            './component/production/sorting/sorting-insert/sorting-insert.module'
          ).then((m) => m.SortingInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/sorting/edit/:id',
        loadChildren: () =>
          import(
            './component/production/sorting/sorting-insert/sorting-insert.module'
          ).then((m) => m.SortingInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/sorting',
        loadChildren: () =>
          import(
            './component/production/sorting/sorting-list/sorting-list.module'
          ).then((m) => m.SortingListModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/execute/create',
        loadChildren: () =>
          import(
            './component/production/execute/execute-insert/execute-insert.module'
          ).then((m) => m.ExecuteInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      
      
      {
        path: 'format-compose',
        loadChildren: () =>
          import('./component/format-compose/format-compose.module').then(
            (m) => m.FormatComposeModule
          ),
      },
    ],
    resolve: { lang: AppModuleLanguageResolver },
  },
  {
    path: 'warehouse',
    component: WarehouseLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./component/warehouse/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      
      {
        path: 'production/goods-receipt',
        loadChildren: () =>
          import(
            './component/warehouse/goods-receive/goods-receive-insert/goods-receive-insert.module'
          ).then((m) => m.GoodsReceiveInsertModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      
      {
        path: 'production/goods-issue',
        loadChildren: () =>
          import(
            './component/warehouse/goods-issue/goods-issue-insert/goods-issue-insert.module'
          ).then((m) => m.GoodsIssueInsertModule),
        resolve: { lang: WarehouseModuleLanguageResolver },
      },
      //{ path: 'production/sorting', loadChildren: () => import('./component/production/sorting/sorting.module').then(m => m.SortingModule), resolve: { lang: ProductionModuleLanguageResolver } },
      {
        path: 'production/inspection',
        loadChildren: () =>
          import(
            './component/production/product-inspection/product-inspection-insert/product-inspection-insert.module'
          ).then((m) => m.ProductInspectionInsertModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/inspectionlist',
        loadChildren: () =>
          import(
            './component/production/product-inspection/product-inspection-list/product-inspection-list.module'
          ).then((m) => m.ProductInspectionListModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/sorting',
        loadChildren: () =>
        import(
          './component/production/sorting/sorting-insert/sorting-insert.module'
        ).then((m) => m.SortingInsertModule),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/sortinglist',
        loadChildren: () =>
        import(
          './component/production/sorting/sorting-list/sorting-list.module'
        ).then((m) => m.SortingListModule),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/Wash',
        loadChildren: () =>
        import('./component/production/wash/wash-create/wash-create.module').then(
          (m) => m.WashCreateModule
        ),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/Washlist',
        loadChildren: () =>
        import(
          './component/production/wash/wash-list/wash-list.module'
        ).then((m) => m.WashListModule),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/Repair',
        loadChildren: () =>
        import(
          './component/production/repair/repair-create/repair-create.module'
        ).then((m) => m.RepairCreateModule),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/Repairlist',
        loadChildren: () =>
        import(
          './component/production/repair/repair-list/repair-list.module'
        ).then((m) => m.RepairListModule),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/Stocksheet',
        loadChildren: () =>
        import(
          './component/warehouse/opening-stock-sheet/opening-stock-sheet-list/opening-stock-sheet-list.module'
        ).then((m) => m.OpeningStockSheetListModule),
      resolve: { lang: WarehouseModuleLanguageResolver },
      },
      {
        path: 'production/todaypl',
        loadChildren: () =>
        import('./component/production/planing/planing.module').then(
          (m) => m.PlaningModule
        ),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/planningw',
        loadChildren: () =>
        import('./component/production/planing/planing.module').then(
          (m) => m.PlaningModule
        ),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/orderwashplan',
        loadChildren: () =>
        import('./component/production/orderwashplanning/orderwashplan.module').then(
          (m) => m.OrderWashPlanModule
        ),
      resolve: { lang: ProductionModuleLanguageResolver },
      },
      {
        path: 'production/planninglist',
        loadChildren: () =>
          import(
            './component/production/planing/planing-list/planing-list.module'
          ).then((m) => m.PlaningListModule),
        resolve: { lang: ProductionModuleLanguageResolver },
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./component/auth/auth.module').then((m) => m.AuthModule),
    resolve: { lang: AuthModuleLanguageResolver },
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () =>
      import('./component/auth/auth.module').then((m) => m.AuthModule),
    resolve: { lang: AuthModuleLanguageResolver },
  },
  {
    path: 'auth/change-password',
    loadChildren: () =>
      import('./component/auth/auth.module').then((m) => m.AuthModule),
    resolve: { lang: AuthModuleLanguageResolver },
  },
  {
    path: 'purchase/external/vendor-offer',
    loadChildren: () =>
      import(
        './component/purchase/vendor-offer/external-vendor-offer-insert/external-vendor-offer-insert.module'
      ).then((m) => m.ExternalVendorOfferInsertModule),
    resolve: { lang: PurchaseModuleLanguageResolver },
  },
  {
    path: 'purchase/external/order-confirmation',
    loadChildren: () =>
      import(
        './component/purchase/purchase-order/external-purchase-order-confirm/external-purchase-order-confirm.module'
      ).then((m) => m.ExternalPurchaseOrderConfirmModule),
    resolve: { lang: PurchaseModuleLanguageResolver },
  },
  {
    path: 'purchase/external/order-change',
    loadChildren: () =>
      import(
        './component/purchase/purchase-order/external-purchase-order-change/external-purchase-order-change.module'
      ).then((m) => m.ExternalPurchaseOrderChangeModule),
    resolve: { lang: PurchaseModuleLanguageResolver },
  },
  {
    path: 'product-view',
    loadChildren: () =>
      import(
        './component/master/product/product-view/product-view.module'
      ).then((m) => m.ProductViewModule),
  },
  {
    path: 'company-profile-edit',
    loadChildren: () =>
      import(
        './component/master/company/company-profile-edit/company-profile-edit.module'
      ).then((m) => m.CompanyProfileEditModule),
  },
  {
    path: 'wash',
    loadChildren: () =>
      import('./component/production/wash/wash.module').then(
        (m) => m.WashModule
      ),
  },
  {
    path: 'wash-create',
    loadChildren: () =>
      import('./component/production/wash/wash-create/wash-create.module').then(
        (m) => m.WashCreateModule
      ),
  },


  //{
  //  path: 'pp-sldashborad',
   // loadChildren: () =>
   //   import('./sldashborad/sldashborad.component').then(
    //    (m) => m.SldashboradComponent
   //   ),
 // },


  {
    path: '**',
    loadChildren: () =>
      import('./component/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    AuthModuleLanguageResolver,
    AppModuleLanguageResolver,
    PurchaseModuleLanguageResolver,
    WarehouseModuleLanguageResolver,
    ProductionModuleLanguageResolver,
  ],
})
export class AppRoutingModule {}
