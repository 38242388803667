import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ProductionService {
  constructor(private http: HttpClient) {}

  GetProducForcast(filter): Observable<Array<Forecast>> {
    const url = `/api/Production/GetProductForcast`;
    return this.http.post<Array<Forecast>>(url, filter).pipe(
      map((data) => {
        return data.map((item) => {
          const model: Forecast = {
            productName: item.productName,
            data: item.data.map((d) => {
              const details: ForecastDetails = {
                currentDay: d.currentDay,
                date: new Date(
                  moment(d.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                ),
                day90Avg: d.day90Avg,
                diff: d.diff,
                lastYear: d.lastYear,
                yoYGrowth: d.yoYGrowth,
              };
              return details;
            }),
          };
          return model;
        });
      })
    );
  }

  SearchProductionPlanning(q: SearchProductionPlaning) {
    const url = `/api/Production/GetProductPlanningDetail`;
    return this.http.post<Array<ProductionPlaningList>>(url, q);
  }

  GetProductionDetail(q: ProductionDetailParam) {
    const url = `/api/Production/GetProductionDetail`;
    return this.http.post<Array<ProductionDetails>>(url, q);
  }

  GetProductionDetailbyproduct(q: ProductionDetailParamLatest) {
    const url = `/api/Production/GetProductionDetailListLikeProductExcel`;
    return this.http.post<Array<ProductionDetails>>(url, q);
  }

  GetProductionDetailbyordproductandwhid(q: SearchProductionPlaning) {
    const url = `/api/Production/GetProductionDetailListBydateandwhid`;
    return this.http.post<Array<ProductionDetails>>(url, q);
  }

  GetProductionDetailLikeExcel(q: ProductionDetailParamExcel) {
    const url = `/api/Production/GetProductionDetailListLikeExcel`;
    return this.http.post<Array<ProductionDetails>>(url, q);
  }

  GetProductionDetailByOrder(q: ProductionDetailParamExcel) {
    const url = `/api/Production/GetProductionDetailListLikeExcelByorder`;
    return this.http.post<Array<ProductionDetails>>(url, q);
  }

  GetProductionDetailByProduct(whid: number, productCd: string) {
    const url = `/api/Production/GetProductionDetailByProduct/${whid}/${productCd}`;
    return this.http.get<Array<ProductionDetails>>(url);
  }

  GetRepairList(whid: number) {
    const url = `/api/WarehouseStock/GetAllStockByWHId/${whid}`;
    return this.http.get<Array<any>>(url);
  }

  CreateProductionOrder(data: CreateProductionOrder) {
    const url = `/api/Production/CreateProductionOrderNew`;
    return this.http.post<any>(url, data);
  }
  CreateBulkProductionOrder(data: Array<CreateProductionOrder>) {
    const url = `/api/Production/CreateBulkproductionOrder`;
    return this.http.post<any>(url, data);
  }
  CreateFastrackProductionOrder(data: CreateProductionOrder) {
    const url = `/api/Production/CreateProductionOrderNew`;
    return this.http.post<any>(url, data);
  }

  UpdateProductionOrder(data: CreateProductionOrder) {
    const url = `/api/Production/UpdateProductionOrder`;
    return this.http.post<any>(url, data);
  }

  GetProductionExecutionByID(productionExecutionid) {
    const url = `/api/ProductionExecution/GetProductionExecutionByID/${productionExecutionid}`;
    return this.http.get<Array<ProductionPlaningList>>(url);
  }

  // GetProductionPlaningList() {
  //   const url = `/api/Production/GetAllOpen/`
  //   return this.http.get<Array<ProductionPlaningList>>(url)
  // }

  GetProductionPlaningList() {
    const url = `/api/Production/GetAllOpen`;
    return this.http.get<Array<ProductionPlaningList>>(url);
  }
  GetProductionPlaningListByDate(fromdate:any,todate:any,searchtype:any) {
    const url = `/api/Production/GetProductionOrderlistByDate/${fromdate}/${todate}/${searchtype}`;
    return this.http.get<Array<ProductionPlaningList>>(url);
  }

  GetProductionExecutionList() {
    const url = `/api/ProductionExecution/GetAll/1000/1`;
    return this.http.get<Array<any>>(url, { headers: { productionType: 'W' } });
  }

  // GetWashList() {
  //   const url = `/api/Production/GetAllOpen/`
  //   return this.http.get<Array<any>>(url)
  // }

  GetWashList() {
    const url = `/api/ProductionExecution/GetAll/1000/1`;
    return this.http.get<Array<any>>(url, { headers: { productionType: 'R' } });
  }

  GetProductionRefDocumentDetail(productCD:string,statusCD:number ) {
    const url = `/api/ProductionExecution/GetProductiondocumentReferencebyproductandStatus/${productCD}/${statusCD}`;
    return this.http.get<Array<any>>(url, { headers: { productionType: 'R' } });
  }

  GetProductionOrderById(id: string) {
    const url = `/api/Production/GetProductionOrderById/${id}`;
    return this.http.get<Array<any>>(url);
  }

  GetAllStockByProduct(whid: string, productcd: string) {
    const url = `/api/WarehouseStock/GetAllStockByProduct/${whid}/${productcd}`;
    return this.http.get<Array<any>>(url);
  }
/*Added on 16.03.2023 */

GenarateEmailandNotiForPlanning(product: string, qty: number) {
  const url = `/api/ProductionExecution/GenarateEmailandNotiForPlanning/${product}/${qty}`;
  return this.http.get<Array<any>>(url);
}
  CreateProductionWash(body: any) {
    const url = `/api/ProductionExecution/CreateProductionWash`;
    return this.http.post<Array<any>>(url, body);
  }

  CreateProductionRepair(body: any) {
    const url = `/api/ProductionExecution/CreateProductionRepair`;
    return this.http.post<Array<any>>(url, body);
  }

  UpdateProductionWash(body: any) {
    const url = `/api/ProductionExecution/UpdateProductionExecution`;
    return this.http.put<Array<any>>(url, body);
  }

  CancelProductionExecution(body: any) {
    const url = `/api/ProductionExecution/CancelProductionExecution`;
    return this.http.put<Array<any>>(url, body);
  }

  CancelOrderExecution(body: any) {
    const url = `/api/Order/CancelOrder`;
    return this.http.put<Array<any>>(url, body);
  }

  CancelProductionOrder(body: any) {
    const url = `/api/Production/CancelProductionOrder`;
    return this.http.put<Array<any>>(url, body);
  }

  GetAllForSelection() {
    const url = `/api/Product/GetAllForSelection`;
    return this.http.get<Array<any>>(url);
  }

  GetOrderDetailByProductAndDate(
    orderType: string,
    whId: number,
    productCd: string,
    fromDate: Date,
    toDate: Date
  ) {
    const url = `​/api/DispatchOrder/GetOrderDetailByProductAndDate/${orderType}/${whId}/${productCd}/${moment(
      fromDate
    ).format('DD-MMM-YYYY')}/${moment(toDate).format('DD-MMM-YYYY')}`;
    return this.http.get<Array<CORDDetailsList>>(url);
  }

  getWashRepairReportData(type:string,fromdate:string,todate:string) {
 

    const url = `/api/ProductionExecution/GetWashAndRepairReport/${type}/${fromdate}/${todate}`;
    return this.http.get<Array<WashAndRepairReportList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const wshorder: WashAndRepairReportList = {
            productionExecutionId: item.productionExecutionId,
            productionExecutionNo: item.productionExecutionNo,
           productCd:item.productCd,
           prodName: item.prodName,
          productionExecutionDate: item.productionExecutionDate,
          whName: item.whName,
          whid:item.whid,
          machineCd:item.machineCd,
          machineName:item.machineName,
          empId:item.empId,
          empName:item.empName,
          statusD:item.statusD,
          statusCd:item.statusCd,
          prevstatusCD:item.prevstatusCD,
          prevStatusD:item.prevStatusD,

            productionQty: item.productionQty,
           timeStart:item.timeStart,
           timeEnd:item.timeEnd,
           timeTakenHour:item.timeTakenHour,
           timeTakenMinute:item.timeTakenMinute
           
          };
          return wshorder;
        });
      })
    );
  }
}


export interface CORDDetailsList {
  despatchOrderNo: string;
  orderNo: string;
  orderDate: Date;
  productCd: string;
  productName: string;
  orderQty: number;
}
export interface ProductionPlaningList {
  id: number;
  productionOrderNo: string;
  productionOrderDate: Date;
  whid: number;
  whName: string;
  machineCd: number;
  machineName: string;
  startDate?: any;
  endDate?: any;
  remarks?: any;
  productionOrderDetails?: any;
}
export interface CreateProductionOrder {
  productionOrderDate: Date;
  productionType: string;
  employeeId: number;
  whId: number;
 
  machineCd: number;
  productionDetails: Array<ProductionOrderDetails>;
  productionOrderSplits: Array<ProductionOrderSplit>;
}

export interface ProductionOrderDetails {
  productCd: string;
  productName?: string;
  userQty: number;
  slotId?:number;
  fromslotid?:number;
  capacity?:number;
  locationId?:number;
  slottotalqty?:number;
  insqty?:number;
  statuscd?:number;
  rfu?:number;
  tbr?:number;
  tbw?:number;
  tbhw?:number;
  scrp?:number;
  locationSlotNo?: string;
  locationName?: string;
}

export interface ProductionDetails {
  orderDate?: Date;
  date?:Date;
  productCd: string;
  productName: string;
  noOfIssue?: any;
  noOfReceive?: any;
  machineCd: number;
  machineName?: any;
  machineCapacity: number;
  systemQty: number;
  crn?:any;
  awi?:number;
  userQty: number;
  productionOrderQty: number;
  rfuQty: number;
  tbwQty: number;
  tbhwQty: number;
  tbrQty: number;
  productionOrderSplits: Array<ProductionOrderSplit>;
}

export interface ProductionDetailParam {
  whId: number;
  productcds: Array<string>;
}

export interface ProductionDetailParamLatest {
  whId: number;
  productcd: string;
  fromDate:string;

  documentId: number,
  orderIds: Array<string>;
  businessType: number;
  bpid: number;
  "growthRate": number;
  productcds: Array<string>;
}

export interface ProductionDetailParamExcel {
  
  whId: number;
  productcds: Array<string>;
  bpid:number;
}
export interface SearchProductionPlaning {
  fromDate: any;
  toDate: any;
  productcds: Array<string>;
  whId: number;
}

export interface ProductionPlaningList {
  orderDate: string;
  productCd: string;
  productName: string;
  noOfIssue: number;
  noOfReceive: number;
  machine: string;
  TotalCORD: number;
  TotalCORDQty: number;
  AvailableQty: number;
}
export interface ProductionForcast {
  productCd: string;
  productName: string;
  transaction: string;
  transDate: Date;
  currentQty: number;
  lastYearQty: number;
  last90Qty: number;
}

export interface ProductForcastFilter {
  fordate: string;
  productcds: Array<string>;
}

export interface ForecastDetails {
  date: Date;
  day90Avg: number;
  currentDay: number;
  lastYear: number;
  diff: number;
  yoYGrowth: number;
}

export interface Forecast {
  productName: string;
  data: ForecastDetails[];
}

export interface ProductionOrderSplit {
  productCd: string;
  userQty: number;
  productionDate: Date;
}

export interface WashAndRepairReportList {
  productionExecutionId?:number,
  productionExecutionNo?:string,
  productionExecutionDate?:any,
  empId?:number,
  empName?:string,
  whid?:number,
  whName?:string,
  machineCd?:number,
  machineName?:string
  productCd?: string;
  prodName?: string;
  statusCd?: number;
  statusD?: string;
  prevstatusCD?: number;
  prevStatusD?: string;
  remarks?: string;
  completionDate?: any;
  timeStart?: any;
  timeEnd?: any;

  timeTakenHour?: any;
  timeTakenMinute?: any;
  productionQty: number;
}
