import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable, Subject } from 'rxjs'
import { RouteInfo } from 'src/app/component/layout/sidebar/sidebar.component'

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  minimizeSidebar: Subject<void> = new Subject<void>()
  toggleSidebar: Subject<void> = new Subject<void>()

  constructor(private translatePipe: TranslatePipe, private router: Router) {}

  getMenuList(): Array<RouteInfo> {
    const ROUTES: RouteInfo[] = [
      {
        path: '/',
        title: this.translatePipe.transform('menu.dashboard'),
        type: 'link',
        icontype: 'dashboard',
      },
      {
        path: '/master',
        title: this.translatePipe.transform('menu.masters'),
        type: 'sub',
        icontype: 'content_paste',
        collapse: 'master',
        children: [
          {
            path: 'profile-edit',
            title: 'Company',
            ab: 'LS',
          },
         /*{
            path: 'lsp',
            title: this.translatePipe.transform('menu.master.lsp'),
            ab: 'LS',
          },
        {
            path: 'movement-type',
            title: this.translatePipe.transform('menu.master.movement-type'),
            ab: 'MT',
          },
          {
            path: 'warehouse-group',
            title: this.translatePipe.transform('menu.master.warehouse-group'),
            ab: 'WG',
          },*/
          {
            path: 'warehouse',
            title: this.translatePipe.transform('menu.master.warehouse'),
            ab: 'WH',
          },
         
         /* {
            path: 'productgroup',
            title: this.translatePipe.transform('menu.master.product-group'),
            ab: 'PG',
          },
          /* {
            path: 'uom',
            title: this.translatePipe.transform('menu.master.uom'),
            ab: 'UO',
          },*/
          {
            path: 'product',
            title: this.translatePipe.transform('menu.master.product'),
            ab: 'PR',
          },
         /* {
            path: 'business-partner-group',
            title: this.translatePipe.transform(
              'menu.master.business-partner-group',
            ),
            ab: 'BG',
          },
           {
            path: 'currency',
            title: this.translatePipe.transform('menu.master.currency'),
            ab: 'CR',
          },
          {
            path: 'department',
            title: this.translatePipe.transform('menu.master.department'),
            ab: 'DP',
          },
          {
            path: 'relationship-type',
            title: this.translatePipe.transform(
              'menu.master.relationship-type',
            ),
            ab: 'RT',
          },*/
          {
            path: 'business-partner',
            title: this.translatePipe.transform('menu.master.business-partner'),
            ab: 'BP',
          },
          {
            path: 'vehicle',
            title: this.translatePipe.transform('menu.master.vehicle'),
            ab: 'VH',
          },
          /*  {
            path: 'machine',
            title: this.translatePipe.transform('menu.master.machine'),
            ab: 'MH',
          },
        
          {
            path: 'route',
            title: this.translatePipe.transform('menu.master.route'),
            ab: 'RO',
          },
          {
            path: 'reasoncode',
            title: this.translatePipe.transform('menu.master.reasoncode'),
            ab: 'RC',
          },*/
          {
            path: 'productstatus',
            title: this.translatePipe.transform('menu.master.productstatus'),
            ab: 'PS',
          },
          /*  {
            path: 'producttype',
            title: this.translatePipe.transform('menu.master.producttype'),
            ab: 'PT',
          },
          {
            path: 'local-resource',
            title: this.translatePipe.transform('menu.master.local-resource'),
            ab: 'LR',
          },
          {
            path: 'state',
            title: this.translatePipe.transform('menu.master.state'),
            ab: 'ST',
          },
          {
            path: 'country',
            title: this.translatePipe.transform('menu.master.country'),
            ab: 'CU',
          },
          {
            path: 'employee',
            title: this.translatePipe.transform('menu.master.employee'),
            ab: 'EM',
          },*/
        ],
      },
      {
        path: '/purchase',
        title: this.translatePipe.transform('menu.purchase'),
        type: 'sub',
        icontype: 'apps',
        collapse: 'components',
        children: [
          {
            path: 'requisition',
            title: this.translatePipe.transform(
              'menu.purchase.purchase-requisition',
            ),
            ab: 'REQ',
          },
          {
            path: 'request-for-quotation',
            title: this.translatePipe.transform(
              'menu.purchase.request-for-quotation',
            ),
            ab: 'RFQ',
          },
          {
            path: 'vendor-offer',
            title: this.translatePipe.transform('menu.purchase.vendor-offer'),
            ab: 'VEO',
          },
          {
            path: 'comparative-offers',
            title: this.translatePipe.transform(
              'menu.purchase.comparative-offers',
            ),
            ab: 'COS',
          },
          {
            path: 'order',
            title: this.translatePipe.transform('menu.purchase.purchase-order'),
            ab: 'POR',
          },
          {
            path: 'stock-level',
            title: this.translatePipe.transform('menu.purchase.stock-level'),
            ab: 'STL',
          },
          // { path: 'notifications', title: 'Notifications', ab: 'N' },
          // { path: 'icons', title: 'Icons', ab: 'I' },
          // { path: 'typography', title: 'Typography', ab: 'T' }
        ],
      },
     
      {
        path: '/logistics',
        title: this.translatePipe.transform('menu.logistic'),
        type: 'sub',
        icontype: 'grid_on',
        collapse: 'logistics',
        children: [
          {
            path: 'ordercreate',
            title: this.translatePipe.transform('menu.logistic.order'),
            ab: 'ORD',
          },
          {
            path: 'multicreateor',
            title: this.translatePipe.transform('menu.logistic.mulord-order'),
            ab: 'MOR',
          },
          {
            path: 'collection-ordercreate',
            title: this.translatePipe.transform(
              'menu.logistic.collection-order',
            ),
            ab: 'CORD',
          },
          {
            path: 'multicreatecr',
            title: this.translatePipe.transform('menu.logistic.mulcollection-order'),
            ab: 'MCOR ',
          },
        /*  {
            path: 'call-off-qty-order',
            title: this.translatePipe.transform(
              'menu.logistic.call-off-qty-order',
            ),
            ab: 'CQ',
          },*/
          {
            path: 'dispatch-plancreate',
            title: this.translatePipe.transform('menu.logistic.dispatch-plan'),
            ab: 'DP',
          },
          {
            path: 'shipment-ordercreate',
            title: this.translatePipe.transform('menu.logistic.shipment-order'),
            ab: 'TORD',
          },
        ],
      },
      
    /* Commited on02.11.2023 {
        path: '/',
        title: this.translatePipe.transform('menu.caam'),
        type: 'sub',
        icontype: 'place',
        collapse: 'caam',
        children: [
          {
            path: 'stock-and-movement',
            title: this.translatePipe.transform('menu.caam.stock-and-movement'),
            ab: 'SM',
          },
          {
            path: 'exchange-Pallet',
            title: this.translatePipe.transform('menu.caam.exchange-pallet'),
            ab: 'EP',
          },
          {
            path: 'pricing-and-condition',
            title: this.translatePipe.transform(
              'menu.caam.pricing-and-condition',
            ),
            ab: 'PC',
          },
          {
            path: 'inbound-movement',
            title: this.translatePipe.transform('menu.caam.inbound-movement'),
            ab: 'IM',
          },
          {
            path: 'invoice-account',
            title: this.translatePipe.transform('menu.caam.invoice-account'),
            ab: 'IA',
          },
        ],
      },*/
      {
        path: '/warehouse/dashboard',
        title: 'Warehouse Dashboard',
        type: 'link',
        icontype: 'dashboard',
      },
      {
        
        path: '/pp-sldashborad',
        title: 'Storage Location',
        type: 'link',
        icontype: 'dashboard',
      },
      {
        path: '/warehouse',
        title: this.translatePipe.transform('menu.warehouse'),
        type: 'sub',
        icontype: 'widgets',
        collapse: 'warehouse',
        children: [
          // { path: 'shock', title: this.translatePipe.transform('menu.warehouse.shock'), ab: 'S' },
          //{ path: 'dashboard', title: this.translatePipe.transform('menu.warehouse.dashboard'), ab: 'WHD' },
          {
            path: 'opening-stock-sheet',
            title: this.translatePipe.transform(
              'menu.warehouse.opening-stock-sheet',
            ),
            ab: 'OSS',
          },
          {
            path: 'opening-stock',
            title: this.translatePipe.transform('menu.warehouse.opening-stock'),
            ab: 'OST',
          },
          {
            path: 'opening-stock-correction',
            title: this.translatePipe.transform(
              'menu.warehouse.opening-stock-correction',
            ),
            ab: 'OSC',
          },
         // {
          //  path: 'stockcorrection-list',
            //title: this.translatePipe.transform(
             // 'menu.warehouse.opening-stock-correction',
           // ),
          //  ab: 'OSC',
         // },
          {
            path: 'goods-receive',
            title: this.translatePipe.transform('menu.warehouse.goods-receive'),
            ab: 'GR',
          },
          {
            path: 'goods-issue',
            title: this.translatePipe.transform('menu.warehouse.goods-issue'),
            ab: 'GI',
          },
        /*  {
            path: 'exchange-issue',
            title: this.translatePipe.transform(
              'menu.warehouse.exchange-issue',
            ),
            ab: 'EI',
          },
          {
            path: 'return-movement',
            title: this.translatePipe.transform(
              'menu.warehouse.return-movement',
            ),
            ab: 'RM',
          },*/
          {
            path: 'warehouse-inventory',
           title: this.translatePipe.transform('menu.warehousehistory.inventory'),
          // title: "Opening/Stock History",
            ab: 'DOC',
          },
        ],
      },

      {
        path: '/production',
        title: this.translatePipe.transform('menu.production'),
        type: 'sub',
        icontype: 'widgets',
        collapse: 'production',
        children: [
          {
            path: 'inspectioncreate',
            title: this.translatePipe.transform('menu.production.inspection'),
            ab: 'IP',
          },
          {
            path: 'sortingcreate',
            title: this.translatePipe.transform('menu.production.sorting'),
            ab: 'ST',
          },
          // { path: 'shock', title: this.translatePipe.transform('menu.warehouse.shock'), ab: 'S' },
        /*   {
            path: 'production-forcast',
            title: this.translatePipe.transform(
              'menu.production.production-forcast',
            ),
            ab: 'FC',
          },*/
          {
            path: 'planning',
            title: this.translatePipe.transform('menu.production.planning'),
            ab: 'PL',
          },
          {
            path: 'todaypl',
            title: 'Heutige Waschplanung',
            ab: 'PL',
          },
          /*  {
            path: 'wash/washcreate',
            title: this.translatePipe.transform('menu.production.wash'),
            ab: 'WA',
          },*/
             {
            path: 'washcreate',
            title: this.translatePipe.transform('menu.production.wash'),
            ab: 'WA',
          },
          {
            path: 'repaircreate',
            title: this.translatePipe.transform('menu.production.repair'),
            ab: 'RP',
          },
       
          
          {
        
            path: 'report',
            title: 'Bericht',
            ab: 'IP',
            
          },
         
        ],
      },
     
      // {
      //   path: '/charts',
      //   title: 'Charts',
      //   type: 'link',
      //   icontype: 'timeline'

      // },
      // {
      //   path: '/calendar',
      //   title: 'Calendar',
      //   type: 'link',
      //   icontype: 'date_range'
      // },
      // {
      //   path: '/pages',
      //   title: 'Pages',
      //   type: 'sub',
      //   icontype: 'image',
      //   collapse: 'pages',
      //   children: [
      //     { path: 'pricing', title: 'Pricing', ab: 'P' },
      //     { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
      //     { path: 'login', title: 'Login Page', ab: 'LP' },
      //     { path: 'register', title: 'Register Page', ab: 'RP' },
      //     { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
      //     { path: 'user', title: 'User Page', ab: 'UP' }
      //   ]
      // }
    ]

    return ROUTES
  }

  getDepotMenuList(): Array<RouteInfo> {
    const DEPOTROUTES: RouteInfo[] = [
      {
        path: '/warehouse/dashboard',
        title: this.translatePipe.transform('menu.dashboard'),
        type: 'link',
        icontype: 'dashboard',
      },
       {
        path: '/',
        title: this.translatePipe.transform('menu.dashboard'),
        type: 'link',
        icontype: 'dashboard',
      },
     {
        path: '/warehouse',
        title: this.translatePipe.transform('menu.warehouse'),
        type: 'sub',
        icontype: 'widgets',
        collapse: 'warehouse',
        children: [
          // { path: 'shock', title: this.translatePipe.transform('menu.warehouse.shock'), ab: 'S' },
          //{ path: 'dashboard', title: this.translatePipe.transform('menu.warehouse.dashboard'), ab: 'WHD' },
          {
            path: 'opening-stock-sheet',
            title: this.translatePipe.transform(
              'menu.warehouse.opening-stock-sheet',
            ),
            ab: 'OSS',
          },      
          {
            path: 'goods-receive',
            title: this.translatePipe.transform('menu.warehouse.goods-receive'),
            ab: 'GR',
          },
          {
            path: 'goods-issue',
            title: this.translatePipe.transform('menu.warehouse.goods-issue'),
            ab: 'GI',
          },
      
          {
            path: 'warehouse-inventory',
           title: this.translatePipe.transform('menu.warehousehistory.inventory'),
          // title: "Opening/Stock History",
            ab: 'DOC',
          },
        ],
      },

      {
        path: '/production',
        title: this.translatePipe.transform('menu.production'),
        type: 'sub',
        icontype: 'widgets',
        collapse: 'production',
        children: [
        
          {
            path: 'inspection',
            title: this.translatePipe.transform('menu.production.inspection'),
            ab: 'IP',
          },
          {
            path: 'sorting',
            title: this.translatePipe.transform('menu.production.sorting'),
            ab: 'ST',
          },
          {
            path: 'wash',
            title: this.translatePipe.transform('menu.production.wash'),
            ab: 'WA',
          },
          {
            path: 'repair',
            title: this.translatePipe.transform('menu.production.repair'),
            ab: 'RP',
          },
         
         
        ],
      },
   
    ]
    return DEPOTROUTES
  }
}
