import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MachineService {

  constructor(
    private http: HttpClient
  ) { }


  createMachine(data: MachineModel) {

    const url = "/api/Machine/CreateMachine";
    return this.http.post<any>(url, data);
  }

  updateMachine(data: MachineModel) {

    const url = "/api/Machine/UpdateMachine";
    return this.http.put<any>(url, data);
  }

  getMachineList() {

    const url = `/api/Machine/GetAll/${10000}/${1}`;
    return this.http.get<Array<MachineListModel>>(url);
  }

  getMachineyId(MachineCd: number) {

    const url = `/api/Machine/GetMachineById/${MachineCd}`;
    return this.http.get<any>(url);
  }

  deleteMachineByID(MachineCd: number) {
    const url = `/api/Machine/DeleteMachine/${MachineCd}`;
    return this.http.delete<any>(url);
  }

  getMachineByProducts(products: Array<string>) {
    const url = `/api/Machine/GetMachineByProducts`;
    return this.http.post<Array<MachineDropdown>>(url, products);
  }

  getMachineDropdown() {
    const url = `/api/Machine/GetAllSelection`;
    return this.http.get<Array<MachineDropdown>>(url);
  }

}
export interface MachineDropdown {
  machineCd: number;
  machineSd: string;
  machineD: string;
  productCd: string;
  productionPerHour: number;
}
export interface MachineModel {
  machineCd: Number,
  machineSd: string,
  machineD: string,
  capacity: Number,
  active: true,
  waterConsump: Number,
  manufacturerId: string,
  yearOfMan: Date,
  dateOfPurchase: Date,
  elecConsump: Number,
  washTemp: Number,
  maintenaceInt: Number,
  conveyorSpeed: Number,
  visible: true,
  productDetails: Array<productDetailModel>
}

export interface productDetailModel {
  id: Number,
  machineCd: Number,
  productCd: string,
  productName: string,
  productionPerHour: Number
}


export interface MachineListModel {
  id: Number,
  machineCd: Number,
  machineSd: string,
  machineD: string,
  active: true,
  waterConsump: Number,
  manufacturerId: string,
  yearOfMan: Date,
  dateOfPurchase: Date,
  elecConsump: Number,
  washTemp: Number,
  maintenaceInt: Number,
  conveyorSpeed: Number,
  visible: true,
  productDetails: Array<productDetailModel>
}



