import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';
import { WarehouseList } from '../core-master/core-master.service';
import { Item } from '@syncfusion/ej2-angular-navigations';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createGoodsReceive(data: GoodsReceiveMaster) {
    var reqHeader = new HttpHeaders({ 
      'header': 'Access-Control-Allow-Origin: ' + '*' ,
    
    });
    const url = '/api/GoodsReceive/CreateGoodsReceive';
    return this.http.post<any>(url, data,{headers: reqHeader});
  }
  updateGoodsReceive(data: GoodsReceiveEdit) {
    const url = '/api/GoodsReceive/UpdateGoodsReceive';
    return this.http.put<any>(url, data);
  }

  cancelGoodsReceive(data: CancelGoodsReceive) {
    const url = '/api/GoodsReceive/CancelGoodsReceive';
    return this.http.put<any>(url, data);
  }
  cancelGoodsIssue(data: CancelGoodsIssue) {
    const url = '/api/GoodsIssue/CancelGoodsIssue';
    return this.http.put<any>(url, data);
  }

  GoodsReceiveList(): Observable<Array<GoodsReceiveList>> {
    const url = '/api/GoodsReceive/GetAll/50/1';
    return this.http.get<Array<GoodsReceiveList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: GoodsReceiveList = {
            id: item.id,
            receiveId: item.receiveId,
            receiveNo: item.receiveNo,
            receiveDate: item.receiveDate,
            bpid: item.bpid,
            bpName: item.bpName,
            whid: item.whid,
            whName: item.whName,
            haulierId: item.haulierId,
            haulierName: item.haulierName,
            status: item.status,
            statusName: item.statusName,
            vehicleNo: item.vehicleNo,
            returnGr: item.returnGr,
            remarks: item.remarks,
          };
          return data;
        });
      })
    );
  }

  GetGoodsReceiveByID(receiveid: number): Observable<GoodsReceiveEdit> {
    const url = `/api/GoodsReceive/GetGoodsReceiveByID/${receiveid}`;
    return this.http.get<GoodsReceiveEdit>(url).pipe(
      map((data) => {
        const model: GoodsReceiveEdit = {
          id: data.id,
          receiveId: data.receiveId,
          receiveDate: data.receiveDate,
          receiveNo: data.receiveNo,
          extGrnno: data.extGrnno,
          bpid: data.bpid,
          bpName: data.bpName,
          whid: data.whid,
          haulierId: data.haulierId,
          haulierName: data.haulierName,
          vehicleNo: data.vehicleNo,
          whName: data.whName,
          orderNo:data.orderNo,
          baseRefDocId:data.baseRefDocId,
          delevaryNote: data.delevaryNote,
          remarks: data.remarks,
          receiveDetails: data.receiveDetails.map((item) => {
            const detail: GoodsReceiveDetailEdit = {
              id: item.id,
              srno: item.srno,
              receiveId: item.receiveId,
              bpid: item.bpid,
              productCd: item.productCd,
              productName: item.productName,
              batchCd: item.batchCd,
              categoryCd: item.categoryCd,
              returnQty: item.returnQty,
              receiveQty: item.receiveQty,
              discrpQty: item.discrpQty,
              slno: item.slno,
              statusCd: item.statusCd,
              status:item.status,
              quantityShipped:item.quantityShipped,
              transNo: item.transNo,
              storageLocationId: item.storageLocationId,
              storageLocation:item.storageLocation,
            };
            return detail;
          }),
        };
        return model;
      })
    );
  }
  GetGoodsReceiveReportbydate(fromdate:string,todate:string) {
    
    const url = `/api/GoodsReceive/GetReceiveReport/${fromdate}/${todate}`;
    return this.http.get<Array<GoodsReceiveReport>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const ReceiveRe: GoodsReceiveReport = {
            receiveId: item.receiveId,
            receiveDate: item.receiveDate,
            receiveNo: item.receiveNo,
            bpid: item.bpid,
            bpName: item.bpName,
           productName:item.productName,
           productCd:item.productCd,
           remarks:item.remarks,
           receiveQty:item.receiveQty,
           vehicleNo:item.vehicleNo,
           statusName:item.statusName,
           status:item.status,
           haulierName: item.haulierName,
          
          
          };
          return ReceiveRe;
        });
      })
    );
  }
  getOpenDispatchOrderList(
    documentType: string
  ): Observable<Array<DispatchOrderList>> {
    const url = `/api/DispatchOrder/GetAllOpen/${documentType}`;
    return this.http.get<Array<DispatchOrderList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const model: DispatchOrderList = {
            id: item.id,
            despatchOrderId: item.despatchOrderId,
            despatchOrderNo: item.despatchOrderNo,
            despatchOrderDate: item.despatchOrderDate,
            orderNo: item.orderNo,
            purchaseOrderNo: item.purchaseOrderNo,
            bpName: item.bpName,
            haulierName: item.haulierName,
            whName: item.whName,
            deliveryDate: item.deliveryDate,
            remarks: item.remarks,
            crn: item.crn,
          };
          return model;
        });
      })
    );
  }

  GetDispatchOrderByID(
    dispatchOrderid: number
  ): Observable<DispatchOrderMaster> {
    const url = `/api/DispatchOrder/GetDispatchOrderByID/${dispatchOrderid}`;
    return this.http.get<DispatchOrderMaster>(url).pipe(
      map((data) => {
        const model: DispatchOrderMaster = {
          id: data.id,
          bpid: data.bpid,
          bpName: data.bpName,
          whid: data.whid,
          haulierId: data.haulierId,
          haulierName: data.haulierName,
          baseRefDocTypeId: data.baseRefDocTypeId,
          baseRefDocId: data.baseRefDocId,
          remarks: data.remarks,
          vehicleNo: data.vehicleNo,
          vehicleRegNo: data.vehicleRegNo,
          vehiclecd: data.vehiclecd,
          whName: data.whName,
          crn: data.crn,
          orderId: data.orderId,
          orderNo: data.orderNo,
          orderDate: data.orderDate,
          lspid: data.lspid,
          despatchOrderId: data.despatchOrderId,
          despatchOrderNo: data.despatchOrderNo,
          despatchOrderDate: data.despatchOrderDate,

          purchaseOrderId: data.purchaseOrderId,
          purchaseOrderNo: data.purchaseOrderNo,
          purchaseOrderDate: data.purchaseOrderDate,
          collectionDate: data.collectionDate,
          deliveryDate: data.deliveryDate,
          dispatchOrderDetail: data.dispatchOrderDetail.map((item) => {
            const detail: DispatchOrderDetail = {
              id: item.id,
              srno: item.srno,
              productCd: item.productCd,
              productName: item.productName,
              quantityDelivered: item.quantityDelivered,
              uomcd: item.uomcd,
              quantityShipped: item.quantityShipped,
              batchCd: item.batchCd,
              categoryCd: item.categoryCd,
              status: item.status,
              cordQuantity: item.quantityDelivered,
              statusCd: item.statusCd,
              storageLocationId: item.storageLocationId,
            };
            return detail;
          }),
        };
        return model;
      })
    );
  }

  PrintForGoodsReceiveByID(receiveId: number) {
    const url = `/api/GoodsReceive/PrintForGoodsReceiveByID/${receiveId}`;
    return this.http.get<string>(url);
  }

  EmailForGoodsReceiveByID(receiveId: number) {
    const url = `/api/GoodsReceive/SendMailGoodsReceiveByID/${receiveId}`;
    return this.http.get<string>(url);
  }

  GetAllStockByProduct(whid: number, productcd: string) {
    const url = `/api/WarehouseStock/GetAllStockByProduct/${whid}/${productcd}`;
    return this.http.get<Array<any>>(url);
  }
  GetAllStockByProductAndStatus(whid: number, productcd: string, statuscd: number) {
    const url = `/api/WarehouseStock/GetAllStockByProductAndStatus/${whid}/${productcd}/${statuscd}`;
    return this.http.get<Array<any>>(url);
  }

  /// Goods Issue Service*******************************************

  createGoodsIssue(data: GoodsIssueMaster) {
    var reqHeader = new HttpHeaders({ 
      'header': 'Access-Control-Allow-Origin: ' + '*' ,
    
    });
    const url = '/api/GoodsIssue/CreateGoodsIssue';
    return this.http.post<any>(url, data,{headers: reqHeader});
  }
  updateGoodsIssue(data: GoodsIssueEdit) {
    const url = '/api/GoodsIssue/UpdateGoodsIssue';
    return this.http.put<any>(url, data);
  }

  GoodsIssueList(): Observable<Array<GoodsIssueList>> {
    const url = '/api/GoodsIssue/GetAll/1000/1';
    return this.http.get<Array<GoodsIssueList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: GoodsIssueList = {
            id: item.id,
            issueId: item.issueId,
            issueNo: item.issueNo,
            issueDate: new Date(item.issueDate),
            bpid: item.bpid,
            bpName: item.bpName,
            whid: item.whid,
            whName: item.whName,
            haulierId: item.haulierId,
            haulierName: item.haulierName,
            status: item.status,
            statusName: item.statusName,
            vehicleNo: item.vehicleNo,
            returnGr: item.returnGr,
            remarks: item.remarks,
          };
          return data;
        });
      })
    );
  }

  GetGoodsIssueByID(issueId: number): Observable<GoodsIssueEdit> {
    const url = `/api/GoodsIssue/GetGoodsIssueByID/${issueId}`;
    return this.http.get<GoodsIssueEdit>(url).pipe(
      map((data) => {
        const model: GoodsIssueEdit = {
          id: data.id,
          issueId: data.issueId,
          issueDate: data.issueDate,
          issueNo: data.issueNo,
          bpid: data.bpid,
          bpName: data.bpName,
          whid: data.whid,
          haulierId: data.haulierId,
          haulierName: data.haulierName,
          vehicleNo: data.vehicleNo,
          whName: data.whName,
          remarks: data.remarks,
          orderNo:data.orderNo,
          deliveryDate:data.deliveryDate,
          dordno:data.dordno +"-"+ data.orderNo,
          crn:data.crn,
          issueDetails: data.issueDetails.map((item) => {
            const detail: GoodsIssueDetailEdit = {
              id: item.id,
              srno: item.srno,
              issueId: item.issueId,
              bpid: item.bpid,
              productCd: item.productCd,
              productName: item.productName,
              batchCd: item.batchCd,
              categoryCd: item.categoryCd,
              issuedQty: item.issuedQty,
              orderQty: item.orderQty,
              discrpQty: item.discrpQty,
              slno: item.slno,
              statusCd: item.statusCd,
              statusName: item.statusName,
              transNo: item.transNo,
              storageLocationId: item.storageLocationId,
            };
            return detail;
          }),
        };
        return model;
      })
    );
  }

  GetGoodsIssueReportbydate(fromdate:string,todate:string) {
    
    const url = `/api/GoodsIssue/GetIssueReport/${fromdate}/${todate}`;
    return this.http.get<Array<GoodsIssueDetailReport>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const issuer: GoodsIssueDetailReport = {
            issueId: item.issueId,
            issueDate: item.issueDate,
            issueNo: item.issueNo,
            bpid: item.bpid,
            bpname: item.bpname,
           prodName:item.prodName,
           productCd:item.productCd,
           orderQty:item.orderQty,
           issuedQty:item.issuedQty,
           vehicleNo:item.vehicleNo,
           statusD:item.statusD,
           statusCd:item.statusCd,
           haulierName: item.haulierName,
          
          
          };
          return issuer;
        });
      })
    );
  }


  PrintForGoodsIssueByID(issueId: number) {
    const url = `/api/GoodsIssue/PrintForGoodsIssueByID/${issueId}`;
    return this.http.get<string>(url);
  }

  EmailForGoodsIssueByID(issueId: number) {
    const url = `/api/GoodsIssue/SendMailGoodsIssueByID/${issueId}`;
    return this.http.get<string>(url);
  }

  /**** Warehouse Stock Services */

  GetOpenWareHouseStockByWHId(
    WHId: number,
    ProdCD: string
  ): Observable<Array<WareHouseStockList>> {
    const url = `/api/WarehouseStock/GetAllOpenByWHId/${WHId}/${ProdCD}`;
    return this.http.get<Array<WareHouseStockList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WareHouseStockList = {
            Id: item.Id,
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            inStockQty: item.inStockQty,
          };
          return data;
        });
      })
    );
  }

  GetOpenWareHouseStockByWHIdLocationId(
    whid: number,
    locationid: number,
    productcd: string
  ): Observable<Array<WareHouseStockList>> {
    const url = `/api/WarehouseStock/GetAllOpenByWHLocationId/${whid}/${locationid}/${productcd}`;
    return this.http.get<Array<WareHouseStockList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WareHouseStockList = {
            Id: item.Id,
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            inStockQty: item.inStockQty,
            slotID:0,
            storageLocationId:item.storageLocationId,
          };
          return data;
        });
      })
    );
  }

  WarehouseLocationSelectionByWhId(WhId: number): Observable<Array<any>> {
    const url = `/api/Warehouse/GetWarehouseLocationByWhId/${WhId}`;
    return this.http.get<Array<any>>(url).pipe(
      map((data) => {
        // return data.map((item) => {
        //   const data: WarehouseLocationSelection = {
        //     id: item.id,
        //     locationName: item.locationName,
        //   };
        //   return data;
        // });
        return data;
      })
    );
  }

  DistinctWarehouseLocationByWhId(WhId: number): Observable<Array<any>> {
    const url = `/api/Warehouse/GetDistinctWHLocationNameByWhId/${WhId}`;
    return this.http.get<Array<any>>(url).pipe(
      map((data) => {
        // return data.map((item) => {
        //   const data: WarehouseLocationSelection = {
        //     id: item.id,
        //     locationName: item.locationName,
        //   };
        //   return data;
        // });
        return data;
      })
    );
  }

  GetWarehouseLocationByName(LName: string): Observable<Array<any>> {
    const url = `/api/Warehouse/GetWHLocationByName/${LName}`;
    return this.http.get<Array<any>>(url).pipe(
      map((data) => {
        // return data.map((item) => {
        //   const data: WarehouseLocationSelection = {
        //     id: item.id,
        //     locationName: item.locationName,
        //   };
        //   return data;
        // });
        return data;
      })
    );
  }

  /**
   * create WareHouseStock
  //  * @params */

  GetAllStockByWHLocationId(WhId: number, locationId: number) {
    const url = `/api/WarehouseStock/GetAllStockByWHLocationId/${WhId}/${locationId}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * create WareHouseStock
   * @param data WareHouseStockCreate
   */
  createWareHouseStock(data: WareHouseStockCreate) {
    const url = '/api/WarehouseStock/CreateWarehouseStock';
    return this.http.post<any>(url, data);
  }

  /**
   * create Bulk WareHouseStock
   * @param data WareHouseStockCreate Array
   */
  createWareHouseStockBulk(data: Array<WareHouseStockCreate>) {
    const url = '/api/WarehouseStock/CreateWarehouseStockBulk';
    return this.http.post<any>(url, data);
  }

  /**** Warehouse Stock correction Services */

  GetWareHouseStockCorrectionByWHId(
    WHId: number,
    ProdCD: string
  ): Observable<Array<WHStockCorrectionList>> {
    const url = `/api/WarehouseStock/GetForCorrectionByWHId/${WHId}/${ProdCD}`;
    return this.http.get<Array<WHStockCorrectionList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHStockCorrectionList = {
            Id: item.Id,
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            inStockQty: item.inStockQty,
            newStockQty: 0,
          };
          return data;
        });
      })
    );
  }

  GetWareHouseStockCorrectionByWHIdLocationId(
    whid: number,
    locationid: number,
    productcd: string
  ): Observable<Array<WHStockCorrectionList>> {
    const url = `/api/WarehouseStock/GetForCorrectionByWHLocationId/${whid}/${locationid}/${productcd}`;
    return this.http.get<Array<WHStockCorrectionList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHStockCorrectionList = {
            Id: item.Id,
            whid: item.whid,
            slotId:item.slotId,
            slotName:item.slotName,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            inStockQty: item.inStockQty,
            newStockQty: 0,
          };
          return data;
        });
      })
    );
  }

  /**
   * correction WareHouseStock
   * @param data WHStockCorrection
   */
  correctionWareHouseStock(data: WHStockCorrection) {
    const url = '/api/WarehouseStock/WarehouseStockCorrection';
    return this.http.post<any>(url, data);
  }

  /**
   * correction Bulk WareHouseStock
   * @param data WHStockCorrection Array
   */
  correctionWareHouseStockBulk(data: Array<WHStockCorrection>) {
    const url = '/api/WarehouseStock/WarehouseStockCorrectionBulk';
    return this.http.post<any>(url, data);
  }

  //#region *** Master => Warehouse

  createWarehouse(data: WarehouseModel) {
    const url = '/api/Warehouse/CreateWarehouse';
    return this.http.post<any>(url, data);
  }

  updateWarehouse(data: WarehouseModel) {
    const url = '/api/Warehouse/UpdateWarehouse';
    return this.http.put<any>(url, data);
  }

  getWarehouseList() {
    const url = `/api/Warehouse/GetAll/${10000}/${1}`;
    return this.http.get<Array<WarehouseModel>>(url);
  }

  deletWarehouseByID(whid: number) {
    const url = `/api/Warehouse/DeleteWarehouse/${whid}`;
    return this.http.delete<any>(url);
  }
  /**
   * Get WareHouseStockSheet By WHId
   * @param WHId
   */
  GetWareHouseStockSheetWHId(
    WHId: number
  ): Observable<Array<WHStockSheetModel>> {
    var reqHeader = new HttpHeaders({ 
      'header': 'Access-Control-Allow-Origin: ' + '*' ,
    
    });
    const url = `/api/WarehouseStock/GetAllStockByWHId/${WHId}`;
    return this.http.get<Array<WHStockSheetModel>>(url,{headers: reqHeader}).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHStockSheetModel = {
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            inStockQty: item.inStockQty,
            openingStockQty: item.openingStockQty,
            collectionToday: item.collectionToday,
            outStanding: item.outStanding,
            SlotId:item.slotId,
            StorageLocationId:item.storageLocationId,
            locationSlotNo:item.locationSlotNo,
            issuedToday: item.issuedToday,
            receivedQty: item.receivedQty,
            availableBalanceForPlanning: item.availableBalanceForPlanning,
          };
          return data;
        });
      })
    );
  }

  //#endregion

  /**
   * Get GetStockUpdatesByWHIdDate previous day ByWHId By WHId date
   * @param WHId
   */
  GetStockUpdatesByWHIdDate(
    WHId: number,
    date: Date
  ): Observable<Array<WHStockUpdHistoryModel>> {
    const url = `/api/WarehouseStock/GetStockUpdatesByWHId/${WHId}/${date}`;
    return this.http.get<Array<WHStockUpdHistoryModel>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHStockUpdHistoryModel = {
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            oldInstockQty: item.oldInstockQty,
            openingStockQty: item.openingStockQty,
            openingStockDate: item.openingStockDate,
            closingStockQty: item.closingStockQty,
            closingStockDate: item.closingStockDate,
          };
          return data;
        });
      })
    );
  }
  GetStockHistoryByWHIDandDate(
    WHId: number,
    date: string
  ): Observable<Array<WHStockUpdHistoryModel>> {

    var reqHeader = new HttpHeaders({ 
      'header': 'Access-Control-Allow-Origin: ' + '*' ,
    
    });
  
  
    const url = `/api/WarehouseStock/GetAllStockHistoryByWHIdAndDate/${WHId}/${date}`;
    return this.http.get<Array<WHStockUpdHistoryModel>>(url,{headers: reqHeader}).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHStockUpdHistoryModel = {
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            inStockQty:item.inStockQty,
            slotId:item.slotId,
            slotNo:item.slotNo,
            oldInstockQty: item.oldInstockQty,
            openingStockQty: item.openingStockQty,
            openingStockDate: item.openingStockDate,
            closingStockQty: item.closingStockQty,
            closingStockDate: item.closingStockDate,
          };
          return data;
        });
      })
    );
  }
  //#endregion

  /**
   * Get GetStockUpdates Today ByWHId By WHId
   * @param WHId
   */
  GetStockUpdatesTodayByWHId(
    WHId: number
  ): Observable<Array<WHTodayStockUpdateModel>> {
    
    var reqHeader = new HttpHeaders({ 
      'header': 'Access-Control-Allow-Origin: ' + '*' ,
    
    });
    const url = `/api/WarehouseStock/GetStockUpdatesTodayByWHId/${WHId}`;
    return this.http.get<Array<WHTodayStockUpdateModel>>(url,{headers: reqHeader}).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHTodayStockUpdateModel = {
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            oldInstockQty: item.oldInstockQty,
            openingStockQty: item.openingStockQty,
            openingStockDate: item.openingStockDate,
            closingStockQty: item.closingStockQty,
            closingStockDate: item.closingStockDate,
          };
          return data;
        });
      })
    );
  }
  /**
   * Get GetStockUpdates Today ByWHId By WHId
   * @param WHId
   */
  GetAllLocationSlotsBywhidandProduct(
    WHId: number,
    doctype:string,
    Locationid:number,
    ProductCD:string,
    statuscd:number,
    dordid:number,
  ): Observable<Array<WHLocationslotModel>> {
    const url = `/api/WarehouseStock/GetAllLocationSlotsByDocTypeandProduct/${WHId}/${doctype}/${Locationid}/${ProductCD}/${statuscd}/${dordid}`;
    return this.http.get<Array<WHLocationslotModel>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHLocationslotModel = {
            whid: item.whid,
            productcd: item.productcd,
            productName: item.productName,
            statuscd: item.statuscd,
            status: item.status,
            insqty: item.insqty,
            capacity: item.capacity,
            isDefault: item.isDefault,
            locationSlotNo: item.locationSlotNo,
            locationID: item.locationId,
            slotId:item.slotId,
            slotstatus:item.slotstatus,
            qty:item.ordqty,
          //  ordqty:item.ordqty,
           ordqty:item.slottotalqty,
            locationName:item.locationName
          };
          return data;
        });
      })
    );
  }
  /**
   * update Bulk  WarehouseStock
   * @param data WHStockUpdUpdateModel Array
   */
  WarehouseStockUpdateBulk(data: Array<WHStockUpdateModel>) {
    const url = '/api/WarehouseStock/WarehouseStockUpdateBulk';
    return this.http.post<any>(url, data);
  }

  /**
   * Get GetStockUpdates Today ByWHId By WHId
   * @param WHId
   */
  GetStockUpdatesByWHId(
    WHId: number,
    date: string
  ): Observable<Array<WHStockUpdHistoryModel>> {
    const url = `/api/WarehouseStock/GetStockUpdatesByWHId/${WHId}/${date}`;
    return this.http.get<Array<WHStockUpdHistoryModel>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHStockUpdHistoryModel = {
            whid: item.whid,
            productCd: item.productCd,
            productName: item.productName,
            statusCd: item.statusCd,
            statusName: item.statusName,
            oldInstockQty: item.oldInstockQty,
            openingStockQty: item.openingStockQty,
            openingStockDate: item.openingStockDate,
            closingStockQty: item.closingStockQty,
            closingStockDate: item.closingStockDate,
          };
          return data;
        });
      })
    );
  }

  //#endregion

  /************ Warehouse Locaion  *********************/

  CreateWarehouseLocation(data: WHLocationCreateModel) {
    const url = '/api/Warehouse/CreateWarehouseLocation';
    return this.http.post<any>(url, data);
  }




  UpdateWarehouseLocation(data: WHLocationCreateModel) {
    const url = '/api/Warehouse/UpdateWarehouseLocation';
    return this.http.put<any>(url, data);
  }
/* developed by vP on 21.07 */

CreateWarehouseLocationSlots(data: WHLocationSlotCreateModel) {
    const url = '/api/Warehouse/CreateWarehouseLocationSlot';
    return this.http.post<any>(url, data);
  }
  UpdateWLocationSlot(data: WHLocationSlotCreateModel) {
    const url = '/api/Warehouse/UpdateWLocationSlot';
    return this.http.put<any>(url, data);
  }
  /**
   * Get WareHouseStockSheet By WHId
   * @param WHId
   */
   GetWareHouseLocSlotsWHId(
    WHId: number
  ): Observable<Array<WHLocationslotModel>> {
    const url = `/api/Warehouse/GetWHLocationSlotbywhid/${WHId}`;
    return this.http.get<Array<WHLocationslotModel>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHLocationslotModel = {
            slotId:item.slotId,
            locationID: item.locationID,
            locationId:item.locationId,
            whid:item.whid,
            isDefault:item.isDefault,
            locationSlotNo: item.locationSlotNo,
            locationName: item.locationName,
            capacity: item.capacity,
            slotstatus:item.slotstatus,
            
          };
          return data;
        });
      })
    );
  }
 /**
   * Get WareHouseStockSheet By WHId
   * @param WHId
   * @param locationid
   */
  GetWareHouseLocSlotsWHIdandLocationID(
    WHId: number,
    locationid:number
  ): Observable<Array<WHLocationslotModel>> {
    const url = `/api/Warehouse/GetWHLocationSlotDtlByWhIdandLocationID/${WHId}/${locationid}`;
    return this.http.get<Array<WHLocationslotModel>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHLocationslotModel = {
            slotId:item.slotId,
            locationID: item.locationID,
            whid:item.whid,
            isDefault:item.isDefault,
            locationSlotNo: item.locationSlotNo,
            locationName: item.locationName,
            capacity: item.capacity,
            slotstatus:item.slotstatus
          };
          return data;
        });
      })
    );
  }
  GetStockCorrectionListrByWHID(
    WHId: number
    
  ): Observable<Array<StockCorrectionList>> {
    const url = `/api/Warehouse/GetAllStockCorrectionDataByWHid/${WHId}`;
    return this.http.get<Array<StockCorrectionList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: StockCorrectionList = {
            SlotID:item.SlotID,
            LocationSlotNo: item.LocationSlotNo,
            ProductCd:item.ProductCd,
            ProdName:item.ProdName,
            StatusCd: item.StatusCd,
            StatusD: item.StatusD,
            OldAvailableBalance: item.OldAvailableBalance,
            NewAvailableBalance:item.NewAvailableBalance,
            Lmdt:item.Lmdt
          };
          return data;
        });
      })
    );
  }
  GetAllLocationsSlots(
  
  ): Observable<Array<WHLocationslotModel>> {
    const url = `/api/Warehouse/GetAllLocationSlot`;
    return this.http.get<Array<WHLocationslotModel>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: WHLocationslotModel = {
            slotId:item.slotId,
            locationID: item.locationID,
            whid:item.whid,
            isDefault:item.isDefault,
            locationSlotNo: item.locationSlotNo,
            locationName: item.locationName,
            capacity: item.capacity,
            slotstatus:item.slotstatus
          };
          return data;
        });
      })
    );
  }
  GetWHLocationSlotbyslotid(
    SLOID: number
  ): Observable<WHLocationslotModel> {
    const url = `/api/Warehouse/GetWHLocationSlotbyslotid/${SLOID}`;
    return this.http.get<WHLocationslotModel>(url).pipe(
      map((data) => {
        
        const data1: WHLocationslotModel = {
          slotId:data.slotId,
            locationID: data.locationID,
            whid:data.whid,
            isDefault:data.isDefault,
            locationSlotNo: data.locationSlotNo,
            locationName: data.locationName,
            capacity: data.capacity,
            slotstatus:data.slotstatus,
        };
        return data;
      })
    );
  }



  /* */

  deletWarehouseLocationByID(locationId: number) {
    const url = `/api/Warehouse/DeleteWarehouseLocation/${locationId}`;
    return this.http.delete<any>(url);
  }


  DeleteWhLocationslot(slotId: number) {
    const url = `/api/Warehouse/DeleteWhLocationslot/${slotId}`;
    return this.http.delete<any>(url);
  }

  GetWarehouseLocationByWhId(WHId: number): Observable<Array<WHLocationModel>> {
    const url = `/api/Warehouse/GetWarehouseLocationByWhId/${WHId}`;
    return this.http.get<Array<WHLocationModel>>(url).pipe(
      map((data) => {
        return data.map((dataItem) => {
          const data1: WHLocationModel = {
            id: dataItem.id,
            whid: dataItem.whid,
            locationName: dataItem.locationName,
            locationNo: dataItem.locationNo,
            isDefault: dataItem.isDefault,
            locationProductStatuses: dataItem.locationProductStatuses.map(
              (item) => {
                const detail: LocationProductStatus = {
                  id: item.id,
                  statusCd: item.statusCd,
                  statusName: item.statusName,
                  BPID:item.BPID,
                  bpName:item.bpName,
                  productName:item.productName
                };
                return detail;
              }
            ),
          };
          return data1;
        });
      })
    );
  }

  GetWarehouseLocationByLocationId(
    locationId: number
  ): Observable<WHLocationModel> {
    const url = `/api/Warehouse/GetWarehouseLocationByLocationId/${locationId}`;
    return this.http.get<WHLocationModel>(url).pipe(
      map((data) => {
        const data1: WHLocationModel = {
          id: data.id,
          whid: data.whid,
          locationName: data.locationName,
          locationNo: data.locationNo,
          isDefault: data.isDefault,
          locationProductStatuses: data.locationProductStatuses.map((item) => {
            const detail: LocationProductStatus = {
              id: item.id,
              statusCd: item.statusCd,
              statusName: item.statusName,
              BPID:item.BPID,
              bpName:item.bpName,
              productName:item.productName
            };
            return detail;
          }),
        };
        return data1;
      })
    );
  }


  GetMappedWarehouseLocationByProduct(
    whid: number,
    productcd:string
  ): Observable<WHLocationModel> {
    const url = `/api/Warehouse/GetMappedWarehouseLocationByProduct/${whid}/${productcd}`;
    return this.http.get<WHLocationModel>(url).pipe(
      map((data) => {
        const data1: WHLocationModel = {
          id: data.id,
          whid: data.whid,
          locationName: data.locationName,
          locationNo: data.locationNo,
          isDefault: data.isDefault,
          locationProductStatuses: data.locationProductStatuses.map((item) => {
            const detail: LocationProductStatus = {
              id: item.id,
              statusCd: item.statusCd,
              statusName: item.statusName,
              BPID:item.BPID,
              bpName:item.bpName,
              productName:item.productName
            };
            return detail;
          }),
        };
        return data1;
      })
    );
  }
}

export interface WarehouseLocationSelection {
  id: number;
  locationName: string;
  locationNoCount:number;

}

export interface DispatchOrderList {
  id: number;
  despatchOrderId: number;
  despatchOrderNo: string;
  despatchOrderDate: Date;
  orderNo: string;
  purchaseOrderNo: number;
  deliveryDate: Date;
  bpName: string;
  crn: string;
  remarks: string;
  whName: string;
  haulierName: string;
}

export interface DispatchOrderMaster {
  id: number;
  bpid: number;
  bpName: string;
  whid: number;
  haulierId: number;
  haulierName: string;
  baseRefDocTypeId: number;
  baseRefDocId: number;
  remarks: string;
  vehicleNo: string;
  vehicleRegNo: string;

  vehiclecd: number;
  whName: string;
  crn: string;
  orderId: number;
  orderNo: string;
  orderDate: Date;
  lspid: number;
  despatchOrderId: number;
  despatchOrderNo: string;
  despatchOrderDate: Date;

  purchaseOrderId: string;
  purchaseOrderNo: string;
  purchaseOrderDate: string;
  collectionDate: Date;
  deliveryDate: Date;
  dispatchOrderDetail: Array<DispatchOrderDetail>;
}

export interface DispatchOrderDetail {
  id: number;
  srno: number;
  productCd: string;
  productName: string;
  quantityDelivered: number;
  uomcd: number;
  quantityShipped: number;
  batchCd: number;
  categoryCd: number;
  status: string;
  cordQuantity: number;
  statusCd: number;
  storageLocationId: number;
  storageLocation? :string;
}

export interface GoodsReceiveMaster {
  extGrnno: string;
  delevaryNote: string;
  receiveDate: string;
  bpid: number;
  whid: number;
  haulierId: number;
  baseRefDocTypeId: number;
  baseRefDocId: number;
  remarks: string;
  returnGr: boolean;
  vehicleNo: string;
  receiveDetails: Array<GoodsReceiveDetail>;
}

export interface GoodsReceiveDetail {
  srno: number;
  receiveId: number;
  bpid: number;
  productCd: string;
  productName: string;
  batchCd: number;
  categoryCd: number;
  returnQty: number;
  receiveQty: number;
  discrpQty: number;
  slno: number;
  statusCd: number;
  transNo: string;
  storageLocationId: number;
}

export interface GoodsReceiveList {
  id: string;
  receiveId: number;
  receiveNo: string;
  receiveDate: string;
  bpid: number;
  bpName: string;
  whid: number;
  whName: string;
  haulierId: number;
  haulierName: string;
  status: string;
  statusName: string;
  remarks: string;
  returnGr: boolean;
  vehicleNo: string;
}
export interface GoodsReceiveReport {
  id?: string;
  receiveId?: number;
  receiveNo?: string;
  receiveDate?: string;
  bpid?: number;
  bpName?: string;
  whid?: number;
  whName?: string;
  haulierId?: number;
  haulierName?: string;
  status?: string;
  statusName?: string;
  remarks?: string;
  vehicleNo?: string;
  productCd?:string;
  productName?:string;
  receiveQty?:number;
}
export interface GoodsReceiveEdit {
  id: number;
  receiveId: 6712;
  receiveNo: string;
  extGrnno: string;
  delevaryNote: string;
  receiveDate: string;
  bpid: number;
  baseRefDocId : number;

  bpName: string;
  whid: number;
  whName: string;
  haulierId: number;
  haulierName: string;
  vehicleNo: string;
  remarks: string;
  orderNo : string;

  receiveDetails: Array<GoodsReceiveDetailEdit>;
}

export interface GoodsReceiveDetailEdit {
  id: number;
  srno: number;
  receiveId: number;
  bpid: number;
  productCd: string;
  productName: string;
  batchCd: number;
  categoryCd: number;
  returnQty: number;
  receiveQty: number;
  discrpQty: number;
  slno: number;
  status:string;
  quantityShipped:number;
  statusCd: number;
  transNo: string;
  storageLocationId: number;
  storageLocation?:string;
}

/***Goods Issue interfaces */

export interface GoodsIssueMaster {
  issueDate: string;
  vehicleNo: string;
  bpid: number;
  haulierId: number;
  whid: number;
  baseRefDocTypeId: number;
  baseRefDocId: number;
  remarks: string;
  issueDetails: Array<GoodsIssueDetail>;
}

export interface GoodsIssueDetail {
  id: number;
  issueId: number;
  bpid: number;
  productCd: string;
  batchCd: number;
  categoryCd: number;
  productName: string;
  orderQty: number;
  issuedQty: number;
  discrpQty: number;
  slno: number;
  statusCd: number;
  transNo: string;
  storageLocationId: number;
}
export interface GoodsIssueList {
  id: string;
  issueId: number;
  issueNo: string;
  issueDate: Date;
  bpid: number;
  bpName: string;
  whid: number;
  whName: string;
  haulierId: number;
  haulierName: string;
  status: string;
  statusName: string;
  remarks: string;
  returnGr: boolean;
  vehicleNo: string;
}

export interface GoodsIssueEdit {
  id: number;
  issueId: number;
  issueNo: string;
  issueDate: string;
  bpid: number;
  bpName: string;
  whid: number;
  dordno?:string;
  orderNo?:string;
  crn?:string;
  deliveryDate?:any;
  
  whName: string;
  haulierId: number;
  haulierName: string;
  vehicleNo: string;
  remarks: string;
  issueDetails: Array<GoodsIssueDetailEdit>;
}

export interface GoodsIssueDetailEdit {
  id: number;
  srno: number;
  issueId: number;
  bpid: number;
  productCd: string;
  productName: string;
  batchCd: number;
  categoryCd: number;
  orderQty: number;
  issuedQty: number;
  discrpQty: number;
  slno: number;
  statusCd: number;
  statusName: string;
  transNo: string;
  storageLocationId: number;
}
export interface GoodsIssueDetailReport {

  issueId?: number;
  issueNo?:string;
  bpid?: number;
  bpname?:string
  productCd?: string;
  vehicleNo?:string;
  prodName?: string;
  orderQty?: number;
  issuedQty?: number;
  discrpQty?: number;
  statusCd?: number;
  statusD?: string;
  haulierName?: string;
  storageLocationId?: number;
  crn?:string,
  issueDate?:Date
  storageLocation?: string;
}
export interface CancelGoodsReceive {
  receiveId: number;
  receiveNo: string;
  statusCode: string;
  reasonCodeId: number;
  cancelled: boolean;
  remarks1: string;
}

export interface CancelGoodsIssue {
  issueId: number;
  issueNo: string;
  statusCode: string;
  reasonCodeId: number;
  cancelled: boolean;
  remarks1: string;
}

export interface WareHouseStockList {
  Id: number;
  whid: number;
  productCd: string;
  productName: number;
  statusCd: number;
  statusName: string;
  inStockQty: number;
  storageLocationId?:number;
  slotID?:number;
  slotnoarray?:any;
}

export interface WareHouseStockCreate {
  whid: number;
  productCd: string;
  statusCd: number;
  inStockQty: number;
  storageLocationId?:number;
  slotID?:number;
}

export interface WHStockCorrectionList {
  Id: number;
  whid: number;
  productCd: string;
  productName: number;
  statusCd: number;
  slotName?:string;
  slotId?:number;
  statusName: string;
  inStockQty: number;
  newStockQty: number;
}

export interface WHStockCorrection {
  whid: number;
  productCd: string;
  statusCd: number;
  SlotID?: number
  oldAvailableBalance: number;
  newAvailableBalance: number;
}

export interface WarehouseModel {
  whid: number;
  lspid: number;
  whname: string;
  address: string;
  city: string;
  stateCd: string;
  countryCd: string;
  pinCode: string;
  phone1: string;
  phone2: string;
  lstVatNo: string;
  vat: number;
  fax: string;
  email: string;
  webSite: string;
  contactName: string;
  costCenterId: number;
  whcode: string;
}

export interface WHStockSheetModel {
  productCd: string;
  productName: string;
  statusCd: number;
  statusName: string;
  inStockQty: number;
  openingStockQty: number;
  collectionToday: any;
  outStanding: number;
  issuedToday: number;
  receivedQty: number;
  SlotId?:number,
  slotId?:number,
  locationSlotNo?:string,
  StorageLocationId?:number,
  storageLocationId?:number,
  availableBalanceForPlanning: number;
}
/**
 * StockUpdatesToday Model
 */
export interface WHTodayStockUpdateModel {
  whid: number;
  productCd: string;
  productName: string;
  statusCd: number;
  statusName: string;
  oldInstockQty: number;
  openingStockDate: Date;
  openingStockQty: number;
  closingStockDate: Date;
  closingStockQty: number;
}

export interface WHStockUpdateModel {
  productCd: string;
  statusCd: number;
  whid: number;
  newOb: number;
  uclosingQty: number;
}

export interface WHStockUpdHistoryModel {
  whid: number;
  productCd: string;
  productName: string;
  statusCd: number;
  statusName: string;
  inStockQty?: number;
  slotId?: number;
  slotNo?: string;
  oldInstockQty: number;
  openingStockDate: Date;
  openingStockQty: number;
  closingStockDate: Date;
  closingStockQty: number;
}

export interface LocationProductStatus {
  id: number;
  statusCd: number;
  statusName: string;
  productName?:string;
  productCD?:string;
  BPID?:number;
  bpName?:string;

}

export interface LocationProductStatusModel {
  id: number;
  storageLocationId: number;
  statusCd: number;
  ProductCD?:string;
  BPID?:number;
}

export interface WHLocationCreateModel {
  id: number;
  whid: number;
  locationName: string;
  locationNo: string;
  isDefault: boolean;
  locationProductStatuses: Array<LocationProductStatusModel>;
}


export interface WHLocationSlotCreateModel {
  slotId: number;
  whid: number;
  capacity:number;
  locationID:number;
 // locationName: string;
  locationSlotNo: string;
  isDefault: boolean;
  
}
export interface WHLocationModel {
  id: number;
  whid: number;
  locationName: string;
  locationNo: string;
  isDefault: boolean;
  locationProductStatuses: Array<LocationProductStatus>;
}
export interface WHLocationslotModel {
  slotId: number;
  whid?: number;
  capacity:number;
  productcd?:string;
  productName?:string;
  statuscd?:number;
  status?:string;
  locationID?:number;
  locationId?:number;
  locationName: string;
  locationSlotNo: string;
  isDefault?: boolean;
  qty?:number;
  ordqty?:number;
  slottotalqty?:number;
  insqty?:number;
  slotstatus?:string;
}

export interface WHLocation {
  id: number;
  whid: number;
  locationName: string;
  locationNo: string;
  isDefault: boolean;
  locationProductStatuses: Array<LocationProductStatus>;
}
export interface UserGRNLOcations {
  dord?: number;
  whid: number;
  locationid: number;
  productcd: string;
  GRNQty?:number;
  productname?:string;
  statusname?:string;
  status?: number;
  slotid?:number;
  
}
export interface StockCorrectionList {
 
  SlotID: number;
  LocationSlotNo: string;
  ProductCd: string;
  ProdName: string;
  StatusCd: number;
  StatusD: string;
  OldAvailableBalance: number;
  NewAvailableBalance: number;
  Lmdt: Date;
 
}
